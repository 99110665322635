import * as React from "react"

const HelpScoutBeacon = () => {
    React.useEffect(() => {
        try {
            // @ts-ignore
            window.Beacon("init", process.env.REACT_APP_HELPSCOUT_BEACON_ID)
            // @ts-ignore
            window.Beacon("config", {
                display: {
                    verticalOffset: 24,
                    horizontalOffset: 24,
                },
            })
        } catch (err) {
            console.log(err)
        }

        return () => {
            try {
                // @ts-ignore
                window.Beacon("destroy")
            } catch (err) {
                console.log(err)
            }
        }
    }, [])

    return null
}

export default React.memo(HelpScoutBeacon)
