import { createContext, useContext, useEffect, useMemo, useState, ReactNode } from "react"
import { useLocation } from "react-router-dom"
import useAuth from "lib/auth/useAuth"
import { Brand } from "Models/brand"

export interface BrandContextType {
    brand?: Brand
    featureFlags: string[]
    setBrandId: (brandId: number) => void
}

const LAST_BRAND_KEY = "selectedBrandID"
const defaultFeatureFlags:string[] = []

export const BrandContext = createContext<BrandContextType>({} as BrandContextType)

export const BrandProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [brand, setBrand] = useState<Brand>()
    const [featureFlags, setFeatureFlags] = useState<string[]>(defaultFeatureFlags)
    const [initialLoading, setInitialLoading] = useState<boolean>(true)

    const { user, brands } = useAuth()

    const setBrandId = (brandId: number) => {
        const newBrand = brands.find((b: Brand) => b.id == brandId)
        if (newBrand) {
            setBrand(newBrand)
            setFeatureFlags([...defaultFeatureFlags, ...newBrand?.featureFlags ?? []])
            sessionStorage.setItem(LAST_BRAND_KEY, newBrand.id)
            localStorage.setItem(LAST_BRAND_KEY, newBrand.id)
            return true
        } else {
            return false
        }
    }

    const clearCachedBrands = () => {
        sessionStorage.removeItem(LAST_BRAND_KEY)
        localStorage.removeItem(LAST_BRAND_KEY)
    }

    // Check for previously selected brand
    useEffect(() => {
        if (brands) {
            let success = false
            // Check Session Storage
            let brandId = sessionStorage.getItem(LAST_BRAND_KEY)

            if (!brandId) {
                // Check Local Storage
                brandId = localStorage.getItem(LAST_BRAND_KEY)
            }

            if (brandId) {
                // Find Brand and set it
                success = setBrandId(+brandId)
                if (!success) {
                    // Could not find brand. Old references?
                    console.log("XXX could not find", brandId)
                    // TODO Clear session and local storage
                }
            }

            if (!success) {
                // Brand still not set
                
                // If admin, set it to -1
                if (user!.admin) {
                    setBrandId(-1)
                }

                // otherwise, let's default to the first brand in the list
                // TODO first ACTIVE brand
                //console.log("setting brand to first", brands[0])
                setBrandId(brands[0].id)
            }
        }
        setInitialLoading(false)
    }, [brands])

    const memoedValue = useMemo(
        () => ({
            brand,
            featureFlags,
            //roles: brand?.roles ?? [],
            setBrandId,
        }),
        [brands, brand]
    )

    return (
        <BrandContext.Provider value={memoedValue}>
            {!initialLoading && children}
        </BrandContext.Provider>
    )
}

export default function useBrand() {
    return useContext(BrandContext)
}
