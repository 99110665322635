import * as React from "react"

const useLocalStorage = (key: string, defaultValue: any) => {
    const initialValue = React.useMemo(() => {
        return window.localStorage.getItem(key) || defaultValue
    }, [key, defaultValue])

    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        if (value) {
            window.localStorage.setItem(key, value)
        }
    }, [key, value])

    return [value, setValue]
}

export default useLocalStorage
