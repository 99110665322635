import { createContext, useContext, useEffect, useMemo, useState, ReactNode } from "react"
import { useLocation } from "react-router-dom"
import { User } from "Models/user"
import { Brand } from "Models/brand"
import { AuthAPI } from "services/auth"

export interface AuthContextType {
    user?: User
    brands?: any
    loading: boolean
    error?: any
    login: (email: string, password: string) => any
    logout: () => void
    refresh: () => void
    isAdmin: boolean
	setBrandNotificationsCount: (brandID: number, count: number) => void
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [user, setUser] = useState()
    const [isAdmin, setIsAdmin] = useState<boolean>(false)
    const [brands, setBrands] = useState<Brand[]>()
    const [error, setError] = useState<any>()
    const [loading, setLoading] = useState<boolean>(true)

    const location = useLocation()

    // reset error state on page change
    useEffect(() => {
        if (error) setError(null)
    }, [location.pathname])

    // Check for currently active session
    useEffect(() => {
        const getCurrentUser: any = async () => {
            try {
                await refresh()
            } catch (err) {
                //console.log("useAuth::getCurrentUser error", err)
                //setError(err)
            }
            setLoading(false)
        }

        getCurrentUser()
    }, [])

	const setBrandNotificationsCount = (brandID: number, count: number) => {
		if (brands) {
			const newBrands = brands.map((b: Brand) => {
				if (b.id == brandID) {
					b.notificationCount = count
				}
				return b
			})
			setBrands(newBrands)
		}
	}

    const login = async (email: string, password: string) => {
        await AuthAPI.login({
            email,
            password,
        })
        await refresh()
    }

    const logout = async () => {
        await AuthAPI.logout()
        setUser(undefined)
        setIsAdmin(false)
        setBrands(undefined)
    }

    const westockAdminBrand = () => {
        return new Brand(
            -1,
            -1,
            "WeStock - Admin",
            "",
            -1,
            "",
            [],
            "",
            true,
            0,
            1,
            1,
            "",
            "",
            "",
            0,
            0,
			[],
            false,
            [],
            "",
            {},
        )
    }

    const refresh = async () => {
        const me = await AuthAPI.getMe()
        if (me) {
            setUser(me.user)

            if (me.user.admin) {
                // If admin, create a dummy westock brand
                const westock = westockAdminBrand()
                me.brands.unshift(westock)
                setIsAdmin(true)
            } else {
                setIsAdmin(false)
            }
            setBrands(me.brands)
        }
    }



    const memoedValue = useMemo(
        () => ({
            user,
            brands,
            loading,
            error,
            login,
            logout,
            refresh,
            isAdmin,
			setBrandNotificationsCount
        }),
        [user, brands, loading, error]
    )

    return (
        <AuthContext.Provider value={memoedValue}>
            { children }
        </AuthContext.Provider>
    )
}

export default function useAuth() {
    return useContext(AuthContext)
}
