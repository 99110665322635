import * as React from "react"
import { BaseProvider, createDarkTheme, createLightTheme } from "baseui"
import { ThemeContext } from "./contexts"
import useLocalStorage from "hooks/useLocalStorage"
import { useStyletron } from "styletron-react"
import deepMerge from "baseui/utils/deep-merge"
import { ToasterContainer } from "baseui/toast"

const DEFAULT_PRIMITIVES = {
    primaryFontFamily: "",
}

const DEFAULT_OVERRIDES = {
    typography: {},
    borders: {
        inputBorderRadius: "5px",
        buttonBorderRadius: "5px",
        popoverBorderRadius: "5px",
        surfaceBorderRadius: "5px",
        tagBorderRadius: "5px",
        checkboxBorderRadius: "5px",
        inputBorderWidth: "2px",
    },
    colors: {
        contentPrimary: "#384457",
        buttonPrimaryFill: "#7593C3",
        buttonPrimaryText: "#FFFFFF",
        buttonPrimaryHover: "#7593C3",
        buttonPrimaryActive: "#7593C3",
        buttonPrimarySelectedFill: "#7593C3",
        buttonPrimarySelectedText: "#FFFFFF",
    },
    lighting: {},
}

const LIGHT_PRIMITIVES = deepMerge({}, DEFAULT_PRIMITIVES, {})
const LIGHT_OVERRIDES = deepMerge({}, DEFAULT_OVERRIDES, {})

const DARK_PRIMITIVES = deepMerge({}, DEFAULT_PRIMITIVES, {})
const DARK_OVERRIDES = deepMerge({}, DEFAULT_OVERRIDES, {})

const THEMES: { [key: string]: any } = {
    light: {
        primitives: LIGHT_PRIMITIVES,
        overrides: LIGHT_OVERRIDES,
    },
    dark: {
        primitives: DARK_PRIMITIVES,
        overrides: DARK_OVERRIDES,
    },
}

const baseProviderOverrides = {
    LayersContainer: {
        props: {
            id: "base-layers",
        },
    },
    AppContainer: {
        style: {
            position: "relative",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
        },
        props: {
            id: "base-root",
        },
    },
}

// Sets the document colorScheme for light/dark mode
// This automatically adjusts browser defaults like scrollbar color
const ColorScheme = () => {
    const { themeName } = React.useContext(ThemeContext)
    const prevThemeName = React.useRef<string>()
    const [css] = useStyletron()
    React.useEffect(() => {
        if (themeName !== prevThemeName.current) {
            const rootEl = document.documentElement
            rootEl.className = css({
                colorScheme: themeName === "dark" ? "dark" : "light",
            })
        }
        prevThemeName.current = themeName
    }, [css, themeName])
    return null
}

const toasterOverrides = {
    Root: {
        style: {
            zIndex: 1000,
            top: "48px",
        },
    },
}

const ToastWrapper = React.memo(({ children }: { children: any }) => {
    const [didMount, setDidMount] = React.useState(false)

    React.useEffect(() => {
        setDidMount(true)
    }, [])

    return (
        <ToasterContainer autoHideDuration={4000} placement={"top"} overrides={toasterOverrides}>
            {didMount ? children : null}
        </ToasterContainer>
    )
})

export const ThemeProvider = ({ children }: { children: any }) => {
    const [themeName, setThemeName] = useLocalStorage("themeName", "light")

    const theme = React.useMemo(() => {
        const themeFn = themeName === "dark" ? createDarkTheme : createLightTheme
        const { primitives, overrides } = THEMES[themeName]
        return themeFn(primitives, { ...overrides, name: themeName })
    }, [themeName])

    const state = React.useMemo(() => {
        return {
            themeName,
            setThemeName,
        }
    }, [themeName, setThemeName])

    return (
        <ThemeContext.Provider value={state}>
            <BaseProvider theme={theme} zIndex={1000} overrides={baseProviderOverrides}>
                <ColorScheme />
                <ToastWrapper>{children}</ToastWrapper>
            </BaseProvider>
        </ThemeContext.Provider>
    )
}
