import { stat } from "fs";
import { ApiResource } from "./API/manageData";
import { Tier } from "./tier";

export class BrandAttribute extends ApiResource {
	static resourceType: string = "attribute"
	id: number;
	name: string;
	constructor(id: number, name: string) {
		super()
		this.id = id
		this.name = name
	}
}

export enum BrandStatus {
	Active = 1,
	Deactivated = 2,
	PaymentMissing = 3,
	PaymentIssue = 4
}

export enum BrandOrigin {
	Admin = 1,
	SignUp = 2
}

interface BrandApiIntegrationStatus {
	[name: string]: boolean
}

interface IImportMethod {
	name: string
	methodSlug: string
}

export class Brand extends ApiResource {
	static resourceType: string = "brand"
	id: number;
	orgId: number;
	name: string;
	description: string;
	manufacturerID: number;
	manufacturerName: string;
	attributes: BrandAttribute[];
	website: string;
	theming: boolean;
	tier: Tier;
	status: BrandStatus;
	origin: BrandOrigin;
	sampling: string;
	dateAdded: string;
	contactEmail: string;
	productCount: number;
	notificationCount: number;
	featureFlags: string[];
	storeImportMethods: IImportMethod[]
	stripe?: boolean;
	stripeCustomerID?: string
	hide: boolean;
	lastRequest: string;
	storeLocatorLink?: string;
	apis?: BrandApiIntegrationStatus;

	constructor(id: number, orgId: number, name: string, description: string, manufacturerID: number,
		manufacturerName: string, attributes: BrandAttribute[], 
		website: string, theming: boolean, tier: Tier, status: BrandStatus, 
		origin: BrandOrigin, sampling: string, dateAdded: string, contactEmail: string, 
		productCount: number, notificationCount: number = 0, featureFlags: string[] = [], hide: boolean, 
		storeImportMethods: IImportMethod[] = [], lastRequest: string, apis: BrandApiIntegrationStatus = {}, stripeCustomerID?: string, storeLocatorLink?: string) {
		super()
		this.id = id
		this.orgId = orgId
		this.name = name
		this.description = description
		this.manufacturerID = manufacturerID
		this.manufacturerName = manufacturerName
		this.attributes = attributes
		this.website = website
		this.theming = theming
		this.tier = tier
		this.status = status
		this.origin = origin
		this.sampling = sampling
		this.dateAdded = dateAdded
		this.contactEmail = contactEmail
		this.productCount = productCount
		this.notificationCount = notificationCount
		this.featureFlags = featureFlags
		this.storeImportMethods = storeImportMethods
		this.stripeCustomerID = stripeCustomerID
		this.hide = hide
		this.lastRequest = lastRequest
		this.apis = apis
		this.storeLocatorLink = storeLocatorLink
	}
}

export enum BrandFeatureFlag {
	segmentation= 'segmentation',
	prospect= 'prospect',
}

export class ApiBrand {
	id: number;
	name: string;
	theming: boolean;
	constructor(id: number, name: string, theming: boolean) {
		this.id = id
		this.name = name
		this.theming = theming
	}
}

export class ApiBrandStatResponse extends ApiResource {
	static resourceType = 'brand'

	total: number
	brandStats: ApiBrandStat[]

	constructor(total: number, brandStats: ApiBrandStat[]) {
		super()
		this.total = total
		this.brandStats = brandStats
	}
}

export class ApiBrandStat extends ApiResource {
	static resourceType = 'brand'
	id: number
	orgId: number
	name: string
	tier: number
	status: BrandStatus
	origin: BrandOrigin
	dateAdded: string
	amountOfProducts: number
	requests: number
	lastRequest: string
	lastLogin: string
	stripeCustomerID: string

	constructor(id: number,
		name: string,
		orgId: number,
		tier: number,
		status: BrandStatus,
		origin: BrandOrigin,
		dateAdded: string,
		amountOfProducts: number,
		requests: number,
		lastRequest: string,
		lastLogin: string,
		stripeCustomerID: string) {
			super()
			this.id = id
			this.orgId = orgId
			this.name = name
			this.tier = tier
			this.status = status
			this.origin = origin
			this.dateAdded = dateAdded
			this.amountOfProducts = amountOfProducts
			this.requests = requests
			this.lastRequest = lastRequest
			this.lastLogin = lastLogin
			this.stripeCustomerID = stripeCustomerID
		}
}

export class Customer extends ApiResource {
	static resourceType: string = "customer"
	id: number;
	email: string;
	timeStamp: string;
	constructor(id: number, email: string, timeStamp: string) {
		super()
		this.id = id
		this.email = email
		this.timeStamp = timeStamp
	}
}

export class OrgCustomer extends ApiResource {
	static resourceType: string = "orgCustomer"

	constructor(
		public id: number,
		public email: string,
		public timeStamp: string,
		public firstName: string,
		public lastName: string,
		public phoneNumber: string,
		public geoHealth: string,
		public emailHealth: string,
		public phoneHealth: string,
		public city: string,
		public state: string,
		public country: string,
		public postalCode: string,
		public createdAt: string,
	) { 
		
		super()
	}
}

export class BrandFormPreferences extends ApiResource {
	static resourceType: string = "brandFormPreferences"
	title: string;
	subtitle: string;
	textColor: string;
	buttonColor: string;
	buttonTextColor: string;
	buttonHoverColor: string;
	buttonHoverText: string;
	formAccent: string;
	backgroundColor: string;
	formColor: string;
	footerColor: string;
	showSelectAll: boolean;
	constructor(title: string, subtitle: string, textColor: string, buttonColor: string, buttonTextColor: string,
		buttonHoverColor: string, buttonHoverText: string, formAccent: string, backgroundColor: string, formColor: string, footerColor: string, showSelectAll: boolean) {
			super()
			this.title = title
			this.subtitle = subtitle
			this.textColor = textColor
			this.buttonColor = buttonColor
			this.buttonTextColor = buttonTextColor
			this.buttonHoverColor = buttonHoverColor
			this.buttonHoverText = buttonHoverText
			this.formAccent = formAccent
			this.backgroundColor = backgroundColor
			this.formColor = formColor
			this.footerColor = footerColor
			this.showSelectAll = showSelectAll
	}
}

export class PromoCode extends ApiResource {
	static resourceType: string = 'promoCode'
	id: number;
	brandID: number;
	code: string;
	redemptionLink: string;
	start: string;
	end: string;
	isDefault: boolean;
	showOnEmail: boolean;
	showOnThankYou: boolean;
	constructor(id: number,
		brandID: number,
		code: string,
		redemptionLink: string,
		start: string,
		end: string,
		isDefault: boolean,
		showOnEmail: boolean,
		showOnThankYou: boolean) {
		super()
		this.id = id
		this.brandID = brandID
		this.code = code
		this.redemptionLink = redemptionLink
		this.start = start
		this.end = end
		this.isDefault = isDefault
		this.showOnEmail = showOnEmail
		this.showOnThankYou = showOnThankYou
	}
}

export class BrandOrg extends ApiResource {
	static resourceType: string = 'brandOrg'
	id: number
	name: string
	constructor(id: number, name: string) {
		super()
		this.id = id
		this.name = name
	}
}
