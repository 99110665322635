import React, { useCallback, useRef, useState } from "react"
import { useLocation, useNavigate, Link } from "react-router-dom"
import qs from "qs"
import Alert from "react-bootstrap/Alert"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Loading from "Components/loading"
import UnauthenticatedSimpleLayout from "layouts/UnauthenticatedSimpleLayout" // This should live at a higher level
import { AuthAPI } from "services/auth"
import { URLS } from "URLS"

interface FormErrorState {
    password: string[]
    confirmPassword: string | null
}

interface FormTouchState {
    password: boolean
    confirmPassword: boolean
}

const MIN_PASSWORD_LENGTH: number = 8

const DEFAULT_ERROR_STATE: FormErrorState = {
    password: [],
    confirmPassword: null,
}

const ForgotPasswordConfirm = () => {
    const location = useLocation()
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
    const email = (queryParams.email as string) ?? ""
    const code = (queryParams.code as string) ?? ""
    const password = useRef<HTMLInputElement | null>(null)
    const confirmPassword = useRef<HTMLInputElement | null>(null)
    const [isExpiredCode, setIsExpiredCode] = useState<boolean>(false)
    const [passwordMatches, setPasswordMatches] = useState<boolean | null>(null)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
    const [isSent, setIsSent] = useState<boolean>(false)
    const [isReset, setIsReset] = useState<boolean>(false)

    const [touched, setTouched] = useState<FormTouchState>({
        password: false,
        confirmPassword: false,
    })
    const [formErrors, setFormErrors] = useState<FormErrorState>(DEFAULT_ERROR_STATE)

    const navigate = useNavigate()

    const handleResend = () => {
        navigate(`${URLS.FORGOT_PASSWORD}?email=${encodeURIComponent(email)}&autosend=1`)
    }

    // TODO move this to a centralized Password Mgmt utility
    const validatePassword = useCallback(() => {
        const currentPassword = password.current?.value || null
        if (currentPassword) {
            const currErrors: string[] = []

            if (currentPassword.length < MIN_PASSWORD_LENGTH) {
                currErrors.push(
                    `Password too short. Must be at least ${MIN_PASSWORD_LENGTH} characters long`
                )
            }
            if (!currentPassword.match(/[A-Z]/)) {
                currErrors.push("Must contain at least 1 uppercase letter")
            }
            if (!currentPassword.match(/[a-z]/)) {
                currErrors.push("Must contain at least 1 lowercase letter")
            }
            if (!currentPassword.match(/[0-9]/)) {
                currErrors.push("Must contain at least 1 number")
            }
            if (!currentPassword.match(/[!@#$%^&*)(+=._-]|[\\/]|[[\]{}<>]/)) {
                currErrors.push("Must contain at least 1 special character")
            }

            setFormErrors((currFormErrors) => ({
                ...currFormErrors,
                password: currErrors,
            }))

            setTouched((currTouched) => ({
                ...currTouched,
                password: true,
            }))
            if (touched.confirmPassword) {
                validateConfirmPassword()
            }
        }
    }, [password.current?.value])

    const validateConfirmPassword = useCallback(() => {
        if (password.current?.value) {
            if (password.current?.value !== confirmPassword.current?.value) {
                setFormErrors((currFormErrors) => ({
                    ...currFormErrors,
                    confirmPassword: "Passwords do not match.",
                }))
            } else {
                setFormErrors((currFormErrors) => ({
                    ...currFormErrors,
                    confirmPassword: null,
                }))
            }
            setTouched((currTouched) => ({
                ...currTouched,
                confirmPassword: true,
            }))
        }
    }, [password.current?.value, confirmPassword.current?.value])

    const submitHandler = async (e: React.FormEvent) => {
        e.preventDefault()
        if (
            password?.current?.value &&
            password?.current.value !== confirmPassword!.current!.value
        ) {
            setPasswordMatches(false)
            return
        }
        if (code && email) {
            setIsSubmitting(true)
            setPasswordMatches(true)
            try {
                const response = await AuthAPI.forgotPasswordConfirm({
                    email,
                    code,
                    newPassword: password!.current!.value,
                })

                if (response.status === 200) {
                    setIsSubmitting(false)
                    setIsSent(true)
                    setIsReset(true)
                } else {
                    setIsSubmitting(false)
                    setErrorMsg(
                        "There was an error submitting your request. Please try again or contact support."
                    )
                }
            } catch (error) {
                setIsSubmitting(false)
                if (error.response?.data === "ExpiredCodeException") {
                    setIsExpiredCode(true)
                    console.log("invalid code")
                    setErrorMsg(
                        "There was an error submitting your request. Please try again or contact support."
                    )
                } else if (
                    error.response.status === 403 &&
                    error.response.data?.name === "InvalidPasswordException"
                ) {
                    setErrorMsg(error.response.data.message)
                } else if (error.response.data?.name === "LimitExceededException") {
                    setErrorMsg("Too many unsuccessful attempts. Please try again after some time.")
                } else {
                    console.log("error response", error.response)
                    setErrorMsg(
                        "There was an error submitting your request. Please try again or contact support."
                    )
                }
            }
        }
    }

    return (
        <UnauthenticatedSimpleLayout>
            <Container fluid>
                <Row className="justify-content-center">
                    <div className="col-md-4 col-12">
                        <div className="form__instructions mt-3">
                            {!isSent ? (
                                <div className="fw-bold">
                                    A request has been made to reset your password. If you made this
                                    request, please enter a new password below.
                                </div>
                            ) : (
                                <div>
                                    <h2>Password Reset Successful</h2>
                                    <div>
                                        <Link to={URLS.LOGIN}>You can now Login</Link>
                                    </div>
                                </div>
                            )}
                        </div>
                        {!isReset && (
                            <>
                                <Form className="mt-3" onSubmit={submitHandler}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="email">Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            size="lg"
                                            id="email"
                                            value={email}
                                            disabled
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="password">New Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            size="lg"
                                            id="password"
                                            ref={password}
                                            isValid={
                                                touched.password && formErrors.password.length === 0
                                            }
                                            isInvalid={formErrors.password.length > 0}
                                            onBlur={validatePassword}
                                            required
                                        />
                                        <Form.Text id="passwordHelpBlock" muted>
                                            Your password must be at least {MIN_PASSWORD_LENGTH}{" "}
                                            characters long and must contain both upper and
                                            lowercase letters, and at least 1 number and special
                                            character.
                                        </Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            <ul>
                                                {formErrors.password.map(
                                                    (error: string, ix: number): JSX.Element => {
                                                        return <li key={`err-${ix}`}>{error}</li>
                                                    }
                                                )}
                                            </ul>
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="confirmPassword">
                                            Confirm New Password
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            size="lg"
                                            isInvalid={
                                                !!touched.confirmPassword &&
                                                !!formErrors.confirmPassword
                                            }
                                            isValid={
                                                !!touched.confirmPassword &&
                                                !formErrors.confirmPassword
                                            }
                                            id="confirmPassword"
                                            ref={confirmPassword}
                                            onBlur={validateConfirmPassword}
                                            required
                                        />
                                        {!passwordMatches && (
                                            <div className="invalid-feedback">
                                                Passwords do not match.
                                            </div>
                                        )}
                                    </Form.Group>
                                    {errorMsg &&
                                        (isExpiredCode ? (
                                            <Alert key="alert-expired" variant="danger">
                                                The reset password email has expired. <br />
                                                Please click the button below to resend the reset
                                                password email.
                                            </Alert>
                                        ) : (
                                            <Alert variant="danger">{errorMsg}</Alert>
                                        ))}
                                    <div>
                                        {isExpiredCode ? (
                                            <Button
                                                type="button"
                                                variant="primary"
                                                onClick={handleResend}
                                            >
                                                Resend Reset Password Email
                                            </Button>
                                        ) : (
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                disabled={
                                                    isSubmitting ||
                                                    formErrors.password.length > 0 ||
                                                    !!formErrors.confirmPassword
                                                }
                                            >
                                                Reset password
                                            </Button>
                                        )}
                                    </div>
                                </Form>
                            </>
                        )}
                    </div>
                </Row>
                {isSubmitting && <Loading>Processing request</Loading>}
            </Container>
        </UnauthenticatedSimpleLayout>
    )
}

export default ForgotPasswordConfirm
