import { Component } from "react"
import { useNavigate } from "react-router-dom"
import styles from "./tile.module.css"
import Tile, { TileProps } from "./tile"

interface Props extends TileProps {
    topCategories: string[]
}

const TopCategoriesTile: React.FC<Props> = ({ topCategories, navigatePath }: Props) => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate("/reports/requests")
    }

    return (
        <Tile navigatePath={navigatePath}>
            <div className={styles.topCategoriesTile}>
                <div className={styles.topCategoriesContainer}>
                    <div className={styles.topCategory1}>{topCategories[0]}</div>
                    <div className={styles.topCategory}>{topCategories[1]}</div>
                    <div className={styles.topCategory}>{topCategories[2]}</div>
                    <div className={styles.topCategory}>{topCategories[3]}</div>
                    <div className={styles.topCategory}>{topCategories[4]}</div>
                </div>
                <div className={styles.testBlock}>
                    <div className={styles.topStoreTitle}>Trending Tags</div>
                </div>
            </div>
        </Tile>
    )
}

export default TopCategoriesTile
