import Confetti from 'react-canvas-confetti';
import styles from './overview.module.css';
import NetworkManager from '../Network/networkManager';
import { ApiBrandOverview } from '../Models/API/overviewAPI';
import Loading from '../Components/loading';
import GraphTile from './Tiles/graphTile';
import TopProductsTile from './Tiles/topProductsTile'
import ThreeStatTile from './Tiles/threeStatTile';
import TopCategoriesTile from './Tiles/topCategoriesTile'
import MapTile from './Tiles/mapTile';
import { CSSProperties } from 'styled-components';
import SingleStatTile from './Tiles/singleStatTile';
import SentimentGraphTile from './Tiles/sentimentGraphTile';
import FilterableBrandView, { FilterableBrandDataViewProps } from '../Components/Base/filterableBrandDataView';

interface Props extends FilterableBrandDataViewProps {

}

interface State {
	overview?: ApiBrandOverview
	requestData: {x: string, y: number}[]
	requestUsers: {x: string, y: number}[]
	smsSubmissions: {x: string, y: number}[]
	step1: boolean
	step2: boolean
	step3: boolean
	step4: boolean
	step1Clicked: boolean
	step2Clicked: boolean
	step3Clicked: boolean
	step4Clicked: boolean
}

interface CheckProps {
	complete: boolean
}

const CheckMarkComponent = (props: CheckProps) => (
	<div className={`${styles.checkLoader} ${props.complete ? styles.completeLoad : ''}`}>
		<div className={`${styles.checkMark} ${props.complete ? styles.drawCheck : ''}`}>
		</div>
	</div>
)
const style: CSSProperties = {
	position: 'fixed',
	height: '100%',
	width: '100%',
	top: 0,
	left: 0,
	pointerEvents: 'none',
	zIndex: 2000
  };

class Overview extends FilterableBrandView<Props, State> {
	showDateFilter = true
	refConfetti: any;
	constructor(props: Props) {
		super(props)
		const step1 = localStorage.getItem('step1') === 'true'
		const step2 = localStorage.getItem('step2') === 'true'
		const step3 = localStorage.getItem('step3') === 'true'
		const step4 = localStorage.getItem('step4') === 'true'
		this.state = {
			requestData: [],
			requestUsers: [],
			smsSubmissions: [],
			step1: step1,
			step2: step2,
			step3: step3,
			step4: step4,
			step1Clicked: false,
			step2Clicked: false,
			step3Clicked: false,
			step4Clicked: false
		}
	}

	render(){
		const { step1, step2, step3, step4 } = this.state

		if(this.state == null || this.state.overview == null) {
			return <Loading />
		} else if(this.state.overview?.productCount == 0) {
			const completed = step1 == true && step2 == true && step3 == true && step4 == true
			return (
				<div className={styles.guideContainer}>
					<Confetti
							style={style}
							refConfetti={(ref) => this.refConfetti = ref}
					/>
					<div className={styles.instructionsContainer}>
						<div className={styles.textContainer}>
							<p className={styles.title}>Welcome to WeStock!</p>
							<p className={styles.subTitle}>Here is a quickstart guide to get your product request form set up.</p>
							<p className={styles.helperText}>Click the buttons on the right to follow the steps. Watch the video for more information.</p>
							<div className={styles.guideSection}> 
								<div className={styles.checkNText}>
									<CheckMarkComponent complete={step1} />
									Set up your brand profile in the settings tab. 
								</div>
								<a className={styles.guideLink} onClick={()=>{this.setState({step1Clicked: true}); localStorage.setItem('step1', 'true');}} href="settings/brand" target="_blank">Brand Profile</a>
							</div>
							<div className={styles.guideSection}> 
								<div className={styles.checkNText}>
									<CheckMarkComponent complete={step2} />
									Set up your form header and logo. 
								</div>
								<a className={styles.guideLink} onClick={()=>{this.setState({step2Clicked: true}); localStorage.setItem('step2', 'true')}} href="form/edit" target="_blank">Edit Form</a>
							</div>
							<div className={styles.guideSection}>
								<div className={styles.checkNText}>
									<CheckMarkComponent complete={step3} />
									Set up your products in the form tab. 
								</div>
								<a className={styles.guideLink} onClick={()=>{this.setState({step3Clicked: true}); localStorage.setItem('step3', 'true')}} href="form/products" target="_blank">Edit Products</a>
							</div>
							<div className={styles.guideSection}>
								<div className={styles.checkNText}>
									<CheckMarkComponent complete={step4} />
									Create a request alert. (OPTIONAL)
								</div>
								<a className={styles.guideLink} onClick={()=>{this.setState({step4Clicked: true}); localStorage.setItem('step4', 'true')}} href="notifications/alerts" target="_blank">Create Alert</a>
							</div>
							{completed == true && <div className={styles.guideFinalSection}>
								That's it! You are done setting up your request form!
								<br />
								<br />
								Check out our form guide to see some ideas on how to best use it! 
								<a className={styles.guideLink} href="form/formGuide" >Form Guide</a>
							</div>}
						</div>
					</div>
					<div className={styles.videoContainer}>
						<iframe className={styles.video} src="https://www.youtube-nocookie.com/embed/LCDLaDRRwx8" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
					</div>
				</div>
			)
		
		} else if(this.state.overview?.totalRequests == 0 && this.state.overview?.totalSmsSubmissions == 0 && this.state.overview?.totalAnswerCounts == 0) {
			return (
				<div className={styles.guideContainer}>
					<div className={styles.instructionsContainer}>
						<div className={styles.textContainer}>
							<p className={styles.title}>You don't seem to have any requests for this time period.</p>
							<p className={styles.subTitle}>Here are some tips to change that.</p>
							<p className={styles.helperText}>Watch the video for more information.</p>
							<ul>
							<li>
							<div className={styles.guideSection}> 
							You can filter the time period for your requests in the top right. 
							</div></li>
							<li><div className={styles.guideSection}> 
							Check out our guide for getting the most out of your form here. <a className={styles.guideLink} href="form/formGuide" >Form Guide</a>
							</div></li>
							<li><div className={styles.guideSection}> 
							Find the url to your product request form here. <a className={styles.guideLink} href="form/source" >Request Form</a>
							</div> </li>
							<li><div className={styles.guideSection}> 
							Reach out to our Customer Success Team for more help. <a href="mailto:customersuccess@westock.io">customersuccess@westock.io</a>
							</div> </li>
							</ul>
						</div>
					</div>
					<div className={styles.videoContainer}>
						<iframe className={styles.video} src="https://www.youtube-nocookie.com/embed/LCDLaDRRwx8" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
					</div>
				</div>
			)
		} else {
			let rankName
			if(this.state.overview.topCategory && this.state.overview.topCategory.name) {
				rankName = this.state.overview.topCategory.name
				const words = rankName.split(" ");

				for (let i = 0; i < words.length; i++) {
					words[i] = words[i][0].toUpperCase() + words[i].slice(1);
				}
				rankName = words.join(' ');
			}
			const rebateSpend = this.state.overview.totalCampaignSpent
			return (
				<div className={styles.OverviewContainer}>
					<GraphTile data={this.state.requestData} label={`${this.state.overview.totalRequests} Requests`} navigatePath={'/data/requests'}/>
					<GraphTile data={this.state.requestUsers} label={`${this.state.overview.totalUsers} Customers`} navigatePath={'/data/requests'}/>
					<TopProductsTile topProducts={this.state.overview.topProducts} navigatePath={'/data/products'}/>
					<ThreeStatTile threeStats={this.state.overview.topStores} label={"Top Banners"} navigatePath={'/data/store-requests'}/>
					<TopCategoriesTile topCategories={this.state.overview.trendingTags} navigatePath={'/data/tags'}/>
					<ThreeStatTile threeStats={this.state.overview.topSources} label={"Top Sources"} navigatePath={'/data/sources'}/>
					<MapTile data={this.state.overview.requestsByState} label={"Requests By State"} navigatePath={'/data/state-requests'} />
					<SingleStatTile stat={rankName ? `${this.state.overview.topCategory.rank}/${this.state.overview.topCategory.total}` : 'Not Enough Requests To Rank In Category'} label={rankName ? `Your ${rankName} Brand Rank` : ''} navigatePath={'/data/leaderboards'}  />
					<SentimentGraphTile answerData={this.state.overview.answerCounts} label={`${this.state.overview.totalAnswerCounts} Feedback Answers`} navigatePath={'/data/formData'}/>
					<GraphTile noDataLabel='No Rebate Opt-Ins' data={this.state.smsSubmissions} label={`${this.state.overview.totalSmsSubmissions} Rebate Opt-Ins`} navigatePath={'/data/campaigns'}/>
					<SingleStatTile stat={rebateSpend ? `$${rebateSpend}` : 'No Rebate Spend'} label={rebateSpend ? `Rebate Spend` : ''} navigatePath={'/data/campaigns'}  />
				</div>
			)
		}		
	}

	componentWillUnmount() {
		window.removeEventListener("focus", ()=>{})
	}

	loadData = async () => {
		try {
			this.setState({overview: undefined, requestData: [], requestUsers: []})
			const overview = await NetworkManager.getBrandOverview(this.props.brand.id, this.props.beginDate, this.props.endDate)
			const requestData: {x: string, y: number}[] = overview.requestCounts.map((data) => {
				return {
					x: data.date,
					y: data.count
				}
			})
			const requestUsers: {x: string, y: number}[] = overview.userCounts.map((data) => {
				return {
					x: data.date,
					y: data.count
				}
			})
			const smsSubmissions: {x: string, y: number}[] = overview.smsSubmissions.map((data) => {
				return {
					x: data.date,
					y: data.count
				}
			})
			if(overview.productCount == 0) {
				this.setState({step3: false, step3Clicked: false})
				localStorage.setItem('step3', 'false')
				localStorage.setItem('complete', 'false')
				window.addEventListener("focus", this.handleFocus)
			}
			this.setState({overview: overview, requestData: requestData, requestUsers: requestUsers, smsSubmissions: smsSubmissions})
		} catch {
			//TODO: Handle Error
		}
		
	}

	handleFocus = () => {
		if(this.state.step1Clicked == true) {
			this.setState({step1: true})
		}
		if(this.state.step2Clicked == true) {
			this.setState({step2: true})
			localStorage.setItem('step2', 'true')
		}
		if(this.state.step3Clicked == true) {
			this.setState({step3: true})
			localStorage.setItem('step3', 'true')
		}
		if(this.state.step4Clicked == true) {
			this.setState({step4: true})
			localStorage.setItem('step4', 'true')
		}
		if((this.state.step1Clicked == true && this.state.step2Clicked == true && this.state.step3Clicked == true && this.state.step4Clicked) ||
		(this.state.step1 == true && this.state.step2 == true && this.state.step3 == true && this.state.step4)) {
			if(this.refConfetti) {
				setTimeout(()=>{if(this.refConfetti){this.refConfetti({particleCount: 200, spread: 180})}}, 2000);
			}			
		}
	}
}

export default Overview;
