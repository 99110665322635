import { api } from "services/api"

const baseFetcher = async (path: string) => {
    //@ts-ignore
    const { data } = await api.get(path)
    return data
}

/**
 * @type { import("swr/dist/types").SWRConfiguration } config
 */
const global = {
    fetcher: baseFetcher,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    revalidateOnReconnect: false,
}

/**
 * @type { import("swr/dist/types").SWRConfiguration } config
 */
const minimal = {
    revalidateIfStale: false,
}

const swrConfigs = {
    global,
    minimal,
}

export default swrConfigs
