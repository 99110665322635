import { Component } from 'react';
import { Brand } from '../../Models/brand';


export interface BrandDataViewProps {
	brand: Brand
	handleDateFilter: (show: boolean) => void
}

abstract class BrandDataView<P extends BrandDataViewProps, S> extends Component<P, S> {
	abstract showDateFilter: boolean

	componentDidMount(): void {
		this.loadData()
		this.props.handleDateFilter(this.showDateFilter)
	}

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>): void {
		if(this.props.brand.id != prevProps.brand.id ) {
			this.loadData()
		}
	}

	abstract loadData(): void 
}

export default BrandDataView;
