import { useState, Component } from "react"
import { useNavigate } from "react-router-dom"
import NetworkManager from "../Network/networkManager"
import styles from "./noAccess.module.css"
import { Brand, BrandStatus } from "../Models/brand"

interface Props {
    brand: Brand
}

interface State {}

const NoAccess: React.FC<Props> = ({ brand }: Props) => {
    const [error, setError] = useState()
    const navigate = useNavigate()

    const handleChangeSubscription = async () => {
        try {
            const result: any = await NetworkManager.changeSubscription(brand.id)
            navigate(result.stripeURL)
        } catch (error) {
            setError(error)
        }
    }

    switch (brand.status) {
        case BrandStatus.PaymentIssue:
            return (
                <div className={styles.container}>
                    There was an issue with your payment. Please update your payment using this
                    button. Thank you!
                    <br />
                    <br />
					Please contact customer success to help with your payment. <a href="mailto:customersuccess@westock.io" target="_blank">customersuccess@westock.io</a>
                </div>
            )
        case BrandStatus.PaymentMissing:
            return (
                <div className={styles.container}>
                    We are missing a payment for your subscription.{" "}
                    <br />
                    <br />
					Please contact customer success to help with your payment. <a href="mailto:customersuccess@westock.io" target="_blank">customersuccess@westock.io</a>
                </div>
            )
        default:
            return (
                <div className={styles.container}>
                    Your subscription has come to an end. But we would love to have you back!
                    <br />
                    <br />
					Please contact customer success to get you back on the platform. <a href="mailto:customersuccess@westock.io" target="_blank">customersuccess@westock.io</a>
                </div>
            )
    }
}

export default NoAccess
