export abstract class ApiResource {
	static resourceType: string
}

export class ApiManageData<T extends ApiResource> {
	limit: number
	offset: number
	total: number
	timeStamp?: string
	value: T[]

	constructor(
		limit: number,
		offset: number,
		total: number,
		value: T[]) {

		this.limit = limit
		this.offset = offset
		this.total = total
		this.value = value
	}
}