import { Component } from 'react';
import {Popover, ArrowContainer } from 'react-tiny-popover';
import { DateFilterRanges } from '../Models/enums'
import styles from './components.module.css'
import Moment from 'moment'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, FocusedInputShape } from 'react-dates'

export interface Props {
	onDateChange: (start: Moment.Moment, end: Moment.Moment, filterRanges: DateFilterRanges) => void
	dateFilter: DateFilterRanges
	beginDate: Moment.Moment | null
	endDate: Moment.Moment | null
	lifeTimeDate?: Moment.Moment
}

export interface State {
	isPopOpen: boolean
	focusedInput: FocusedInputShape | null
}

// To get the z-index right I had to put an !important in index.css
class DateFilter extends Component<Props, State> {
	datePicker = (beginDate: Moment.Moment | null, endDate: Moment.Moment | null) => (
		<div className={styles.datesContainer}>
			<DateRangePicker 
			startDate={beginDate} // momentPropTypes.momentObj or null,
			startDateId="startDate" // PropTypes.string.isRequired,
			endDate={endDate} // momentPropTypes.momentObj or null,
			endDateId="endDate" // PropTypes.string.isRequired,
			onDatesChange={({ startDate, endDate }) => {
				if(startDate && endDate) {
					this.props.onDateChange(startDate, endDate, this.props.dateFilter)
				}				
			}} // PropTypes.func.isRequired,
			focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
			onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
			withPortal= {true}
			enableOutsideDays= {true}
			isOutsideRange ={(day: Moment.Moment) => (day.isBefore("2019-01-01"))}
			/>
		</div>
	);

	constructor(props: Props) {
		super(props)
		this.state = {
			isPopOpen: false,
			focusedInput: "startDate"
		}
	}

	render(){
		let filterLabel: string = this.props.dateFilter
		if(this.props.dateFilter === DateFilterRanges.custom) {
			if(this.props.beginDate && this.props.endDate) {
				filterLabel = `${this.props.beginDate.format("MMM/DD/YYYY")} - ${this.props.endDate.format("MMM/DD/YYYY")}`
			}
		}
		if(this.state.isPopOpen === false) {
			return (
				<div className={styles.dateFilterContainer}>
					<div onClick={()=>this.setState({isPopOpen: !this.state.isPopOpen})}>
							{filterLabel}
							<img className={styles.chevron} src={`${process.env.REACT_APP_DASH_IMAGE_URL}/chevron.png`} />
					</div>
				</div>
			)
		} else {
			return (
				<div className={styles.dateFilterContainer}>
					<Popover onClickOutside={(e:any)=>{if (e.target.isConnected) {this.setState({isPopOpen: !this.state.isPopOpen})}}} containerStyle={{zIndex: "3000"}} isOpen={this.state.isPopOpen} positions={['bottom']} content={({ position, childRect, popoverRect }) => (
						<ArrowContainer position={position} childRect={childRect} popoverRect={popoverRect} arrowColor={'rgb(224, 225, 227)'} arrowSize={5}>
							<div className={styles.popContainer}>
								<table className={styles.dateSelectionTable}>
									<tbody>
										{
											Object.keys(DateFilterRanges).map((filter) => 
												<tr key={filter}>
													<td onClick={()=>this.handleDateFilter(DateFilterRanges[filter as keyof typeof DateFilterRanges])}>
													{DateFilterRanges[filter as keyof typeof DateFilterRanges]}
													{DateFilterRanges[filter as keyof typeof DateFilterRanges] === this.props.dateFilter ? <img className={styles.checkmark} src={`${process.env.REACT_APP_DASH_IMAGE_URL}/checkmark.png`} /> : ""}
													</td>
												</tr>
											)
										}
									</tbody>
								</table>
								{this.props.dateFilter === DateFilterRanges.custom ? this.datePicker(this.props.beginDate, this.props.endDate) : ""}
							</div>
						</ArrowContainer>					
					)}>
						<div onClick={()=>this.setState({isPopOpen: !this.state.isPopOpen})}>
							{filterLabel}
							<img className={styles.chevron} src={`${process.env.REACT_APP_DASH_IMAGE_URL}/chevron.png`} />
						</div>
					</Popover>
				</div>
			)
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>): void {
		if(!prevProps.lifeTimeDate?.isSame(this.props.lifeTimeDate)) {
			if(prevProps.lifeTimeDate != this.props.lifeTimeDate) {
				this.handleDateFilter(this.props.dateFilter)
			}			
		}
	}

	handleDateFilter = (filter: DateFilterRanges) => {
		let beginDate = Moment()
		let endDate = Moment()

		switch (filter) {
			case DateFilterRanges.last7: {
				beginDate = beginDate.subtract('7', 'days')
				break;
			}
			case DateFilterRanges.last30: {
				beginDate = beginDate.subtract('30', 'days')
				break;
			}
			case DateFilterRanges.last60: {
				beginDate = beginDate.subtract('60', 'days')
				break;
			}
			case DateFilterRanges.last90: {
				beginDate = beginDate.subtract('90', 'days')
				break;
			}
			case DateFilterRanges.lifetime: {
				const { lifeTimeDate } = this.props
				beginDate = lifeTimeDate ? lifeTimeDate : Moment('2019-01-01')
				break;
			}
			case DateFilterRanges.custom: {
				beginDate = beginDate.subtract('7', 'days')
				break;
			}
		}
		if(filter !== DateFilterRanges.custom) {
			this.setState({isPopOpen: false})
		}

		this.props.onDateChange(beginDate, endDate, filter)
	}
}

export default DateFilter;
