/// <reference types="react-vis-types" />
import { useCallback, useEffect, useState, Component } from "react"
import { useNavigate } from "react-router-dom"
import styles from "./tile.module.css"
import Tile, { TileProps } from "./tile"
import {
    XYPlot,
    VerticalBarSeries,
    XAxis,
    LineSeries,
    FlexibleXYPlot,
    YAxis,
    Hint,
} from "react-vis"
import { format } from "d3"
import BarGraph from "../../Components/barGraph"

interface Props extends TileProps {
    data: { x: string; y: number }[]
    label: string
	noDataLabel?: string
}

const GraphTile: React.FC<Props> = ({ data, label, navigatePath, noDataLabel }: Props) => {
    const [hoverValue, setHoverValue] = useState()
    const [, updateState] = useState<any>()
    const navigate = useNavigate()
    const forceUpdate = useCallback(() => updateState({}), [])

    useEffect(() => {
        setTimeout(() => {
            window.requestAnimationFrame(() => {
                forceUpdate()
            })
        }, 0)
    }, [])

    const handleOnMouseOver = (value: any) => {
        setHoverValue(value)
    }

    const handleOnMouseOut = () => {
        setHoverValue(undefined)
    }
    if (data && data.length != 0) {
        return (
            <Tile navigatePath={navigatePath}>
                <div className={styles.graphContainer}>
                    <BarGraph fontSize={14} data={data} />
                </div>
                <div className={styles.graphTitleContainer}>
                    <p className={styles.graphTitle}>{label}</p>
                </div>
            </Tile>
        )
    } else {
        return <Tile navigatePath={navigatePath}>{noDataLabel ? noDataLabel : 'No Data'}</Tile>
    }
}

export default GraphTile
