import { api } from "./api"
import { defineCancelApiObject } from "./axiosUtils"

interface IPasswordChange {
    email: string
    code: string
    newPassword: string
}

interface IForcePasswordChange {
    newPassword: string
}

interface ICredentials {
    email: string
    password: string
}

export const AuthAPI = {
    login: async function (credentials: ICredentials, cancel: boolean = false) {
        const response = await api.request({
            url: `/auth/login`,
            method: "POST",
            data: { ...credentials, scope: "dashboard" },
            signal: cancel
                ? cancelApiObject[this.login.name].handleRequestCancellation().signal
                : undefined,
            withCredentials: true,
        })

        return response.data
    },

    logout: async function (cancel: boolean = false) {
        const response = await api.request({
            url: `/auth/logout`,
            method: "POST",
            data: {},
            signal: cancel
                ? cancelApiObject[this.logout.name].handleRequestCancellation().signal
                : undefined,
            withCredentials: true,
        })

        return response.data
    },

    forgotPassword: async function (email: string, cancel: boolean = false) {
        const response = await api.request({
            url: `/auth/forgot_password`,
            method: "POST",
            data: { email, scope: "dashboard" },
            signal: cancel
                ? cancelApiObject[this.forgotPassword.name].handleRequestCancellation().signal
                : undefined,
        })

        return response
    },

    forgotPasswordConfirm: async function (
        passwordChangeData: IPasswordChange,
        cancel: boolean = false
    ) {
        const response = await api.request({
            url: `/auth/forgot_password/confirm`,
            method: "POST",
            data: { ...passwordChangeData, scope: "dashboard" },
            signal: cancel
                ? cancelApiObject[this.forgotPasswordConfirm.name].handleRequestCancellation()
                      .signal
                : undefined,
            withCredentials: false,
        })

        return response
    },

    forcePasswordChange: async function (
        forcePasswordChangeData: IForcePasswordChange,
        cancel: boolean = false
    ) {
        const response = await api.request({
            url: `/auth/force_password_change`,
            method: "POST",
            data: { ...forcePasswordChangeData, scope: "dashboard" },
            signal: cancel
                ? cancelApiObject[this.forcePasswordChange.name].handleRequestCancellation().signal
                : undefined,
            withCredentials: true,
        })

        return response
    },

    getMe: async function (cancel: boolean = false) {
        const response = await api.request({
            url: `/auth/me`,
            method: "GET",
            signal: cancel
                ? cancelApiObject[this.getMe.name].handleRequestCancellation().signal
                : undefined,
        })
        return response.data
    },
}

// Defining Cancel API object
const cancelApiObject = defineCancelApiObject(AuthAPI)
