import { Component } from "react"
import { useNavigate } from "react-router-dom"
import styles from "./tile.module.css"
import Tile, { TileProps } from "./tile"

interface Props extends TileProps {
    threeStats: { label: string; count: number }[]
    label: string
}

class TopStore {
    store: string
    count: number
    constructor(store: string, count: number) {
        this.store = store
        this.count = count
    }
}

const ThreeStatTile: React.FC<Props> = ({ threeStats, navigatePath, label }: Props) => {
    const navigate = useNavigate()

    if (threeStats.length == 0) {
        return <Tile navigatePath={navigatePath}>No Requests</Tile>
    } else if (threeStats.length == 1) {
        return (
            <Tile navigatePath={navigatePath}>
                <div className={styles.topStoresTile}>
                    <div className={styles.topStoresContainer}>
                        <div className={styles.topStoresChild}>
                            <div className={styles.verticalCenter}>
                                <div className={styles.storeCount}>{threeStats[0].count}</div>
                                <div className={styles.storeName}>{threeStats[0].label}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.testBlock}>
                        <div className={styles.topStoreTitle}>{label}</div>
                    </div>
                </div>
            </Tile>
        )
    } else if (threeStats.length == 2) {
        return (
            <Tile navigatePath={navigatePath}>
                <div className={styles.topStoresTile}>
                    <div className={styles.topStoresContainer}>
                        <div className={styles.topStoresChild}>
                            <div className={styles.verticalCenter}>
                                <div className={styles.storeCount}>{threeStats[0].count}</div>
                                <div className={styles.storeName}>{threeStats[0].label}</div>
                            </div>
                        </div>
                        <div className={styles.topStoresChild}>
                            <div className={styles.verticalCenter}>
                                <div className={styles.storeCount}>{threeStats[1].count}</div>
                                <div className={styles.storeName}>{threeStats[1].label}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.testBlock}>
                        <div className={styles.topStoreTitle}>{label}</div>
                    </div>
                </div>
            </Tile>
        )
    }
    return (
        <Tile navigatePath={navigatePath}>
            <div className={styles.topStoresTile}>
                <div className={styles.topStoresContainer}>
                    <div className={styles.topStoresChild}>
                        <div className={styles.verticalCenter}>
                            <div className={styles.storeCount}>{threeStats[0].count}</div>
                            <div className={styles.storeName}>{threeStats[0].label}</div>
                        </div>
                    </div>
                    <div className={styles.topStoresChild}>
                        <div className={styles.verticalCenter}>
                            <div className={styles.storeCount}>{threeStats[1].count}</div>
                            <div className={styles.storeName}>{threeStats[1].label}</div>
                        </div>
                    </div>
                    <div className={styles.topStoresChild}>
                        <div className={styles.verticalCenter}>
                            <div className={styles.storeCount}>{threeStats[2].count}</div>
                            <div className={styles.storeName}>{threeStats[2].label}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.testBlock}>
                    <div className={styles.topStoreTitle}>{label}</div>
                </div>
            </div>
        </Tile>
    )
}

export default ThreeStatTile
