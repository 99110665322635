import React, { useEffect } from "react"
import { useNavigate, Routes, Route } from "react-router-dom"
import { useAnalytics } from "lib/useAnalytics"

import Dashboard from "./Dashboard/dashboard"
import TermsForm from "./Components/termsForm"
import Loading from "./Components/loading"
import useAuth from "./lib/auth/useAuth"
import useBrand from "lib/useBrand"
import Login from "pages/Login"
import Logout from "pages/Logout"
import ForgotPassword from "pages/ForgotPassword" // TODO lazy
import ForgotPasswordConfirm from "pages/ForgotPasswordConfirm" // TODO lazy
import ForcePasswordChange from "pages/ForcePasswordChange" // TODO lazy
import { URLS, URLS_UNPROTECTED } from "URLS"

import "./App.css"
import "react-dates/lib/css/_datepicker.css"
import "react-vis/dist/main.scss"

const Admin = React.lazy(() => import("Admin/admin"))

interface Props {}

const App: React.FC<Props> = (Props) => {
    const navigate = useNavigate()
    const { user, brands, refresh, loading: userLoading } = useAuth()
    const { brand } = useBrand()
    useAnalytics()

    useEffect(() => {
        if (user) {
        } else if (URLS_UNPROTECTED.includes(window.location.pathname.replace(/\/$/, ""))) {
        } else if (!userLoading) {
            // Not unprotected, No User
            // TODO: Handle initial load
            navigate(URLS.LOGIN)
        }
    }, [user, userLoading])

    const handleOnAcceptTerms = () => {
        refresh()
    }

    
    if (user && brand) {
        if (user.admin) {
            return (
                <React.Suspense fallback={<Loading />}>
                    <Routes>
                        <Route path="*" element={<Admin />} />
                    </Routes>
                </React.Suspense>
            )
        }
        // if the user has not accepted the terms, they need to.
        // TODO move this to middleware
        if (user.acceptedTerms != true) {
            return <TermsForm callback={handleOnAcceptTerms} />
        }
        return (
            <Routes>
                <Route path="*" element={<Dashboard user={user} brands={brands} />} />
            </Routes>
        )
    } else {
        // Unprotected routes
        return (
            <>
                <React.Suspense fallback={<Loading />}>
                    <Routes>
                        <Route path={URLS.LOGIN} element={<Login />} />

                        <Route path={URLS.LOGOUT} element={<Logout />} />

                        <Route path={URLS.TERMS} element={<TermsForm />} />
                        <Route path={URLS.FORGOT_PASSWORD} element={<ForgotPassword />} />
                        <Route
                            path={URLS.FORGOT_PASSWORD_CONFIRM}
                            element={<ForgotPasswordConfirm />}
                        />
                        <Route
                            path={URLS.FORCE_PASSWORD_CHANGE}
                            element={<ForcePasswordChange />}
                        />

                        <Route index element={<Loading />} />
                    </Routes>
                </React.Suspense>
            </>
        )
    }
}

export default App
