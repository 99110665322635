import styles from './components.module.css'
import Modal from 'react-modal';

export default ({isOpen, error, onDismiss}: {isOpen: boolean, error: any, onDismiss:()=>void}) => {
	let title = "Error"
	let message:any = ""
	if (error.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		title = `Error: ${error.response.status} ${error.response.statusText}`
		message = error.response.data
		if(typeof message === 'object') {
			message = ''
			if(message.message) {
				message = message.message
			}
		}
		} else if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		title = `Error: ${error.request.status} ${error.request.statusText}`
		message = error.message
		} else {
		title = "Unknown Error"
		message = error.message
	}
	console.log(message)
	return (
	<Modal 
	className={styles.modalContainer} 
	shouldReturnFocusAfterClose={false}
	isOpen={isOpen}  
	ariaHideApp={false}
	onRequestClose={onDismiss} 
	shouldCloseOnOverlayClick={true} > 
		<>
	<p className={styles.modalTitle}>{title}</p>
	{message}
	<br />
	<button className={styles.modalButton} onClick={onDismiss} type="button">OK</button>
		</>
	</Modal>
	)
}
