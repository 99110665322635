/// <reference types="react-vis-types" />
import { Component } from 'react';
import { VerticalBarSeries, XAxis, FlexibleXYPlot, YAxis, Hint, RVNearestXData, VerticalBarSeriesPoint } from 'react-vis';
import { format } from 'd3';
import styles from './components.module.css'
import { AnswerCount } from '../Models/API/overviewAPI';

interface Props {
	answerData: AnswerCount[]
	fontSize: number
}

interface State {
	index?: number
	dataPoint?: VerticalBarSeriesPoint
	positiveData:{x: any, y: any, color:string}[]
	negativeData:{x: any, y: any, color:string}[]
	neutralData:{x: any, y: any, color:string}[]
}

class SentimentGraph extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		const data = this.formatData(props.answerData)

		this.state = {
			index: undefined,
			positiveData: data.positiveData,
			negativeData: data.negativeData,
			neutralData: data.neutralData
		}
	}

	render(){
		const { dataPoint, index, positiveData, negativeData, neutralData } = this.state;
		const { answerData } = this.props

		if(positiveData.length == 0 && negativeData.length == 0 && neutralData.length == 0) {
			return (
				<div>
					No Feedback
				</div>
			)
		}

		let hover
		if(index) {
			hover = answerData[index]
		}

		const dateOptions: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', timeZone: 'UTC' }
		return (
			<FlexibleXYPlot xType="ordinal" margin={{left: 50, right: 35, top: 35}} onMouseLeave={this.handleOnMouseLeave} stackBy="y">
				<XAxis tickFormat={function tickFormat(d: any){return (new Date(d).toLocaleDateString('en-US',dateOptions))}}
				tickValues={[answerData[0].date, answerData[Math.ceil((answerData.length-1) / 2)].date, answerData[answerData.length - 1].date]}
				style={{
					fontSize: '16px', fontWeight: 'normal', 
					line: {
						//stroke: '#C4C4D5'
					}
				}}/>
				<YAxis tickFormat={(tick: any) => {
					if(Math.round(tick) !== tick) {
						return ""
					}
					if(tick < 1) {
						return tick
					}
					return format('.2~s')(tick)}
				} style={{
					fontSize: '16px', fontWeight: 'normal',
					line: {
						//stroke: '#C4C4D5'
					}
				}}/>
				<VerticalBarSeries colorType={'literal'} data={negativeData} animation stroke={'none'} onNearestX={this.handleOnNearestX} />
				<VerticalBarSeries colorType={'literal'} data={neutralData} animation stroke={'none'} onNearestX={this.handleOnNearestX} />
				<VerticalBarSeries colorType={'literal'} data={positiveData} animation stroke={'none'} onNearestX={this.handleOnNearestX} />				
				{hover ? <Hint value={dataPoint} style={{fontSize: this.props.fontSize}}>
				<div className={styles.hintBody}>
					<p><b>{new Date(hover.date).toLocaleDateString('en-US',dateOptions)}</b></p>
					<p>Postive: {hover.positive}</p>
					<p>Negative: {hover.negative}</p>
					<p>Neutral: {hover.neutral}</p>
				</div>
				</Hint>: null}
			</FlexibleXYPlot>
		)
	}

	componentDidUpdate(prevProps: Props) {
		if(this.props.answerData != prevProps.answerData) {
			this.updateData(this.props.answerData)
		}
	}

	updateData(answerData: AnswerCount[]) {
		const data = this.formatData(answerData)
		this.setState({
			positiveData: data.positiveData,
			negativeData: data.negativeData,
			neutralData: data.neutralData
		})
	}

	formatData(answerData: AnswerCount[]) {
		let positiveData:{x: any, y: any, color:string}[] = []
		let negativeData:{x: any, y: any, color:string}[] = []
		let neutralData:{x: any, y: any, color:string}[] = []

		answerData.forEach((d) => {
			positiveData.push({
				x: d.date,
				y: parseInt(d.positive),
				color: '#38573b'
			})
			negativeData.push({
				x: d.date,
				y: parseInt(d.negative),
				color: '#831100'
			})
			neutralData.push({
				x: d.date,
				y: parseInt(d.neutral),
				color: '#384457'
			})
		})

		return {
			positiveData: positiveData,
			negativeData: negativeData,
			neutralData: neutralData
		}
	}

	handleOnNearestX = (dataPoint: VerticalBarSeriesPoint, data: RVNearestXData<VerticalBarSeriesPoint>) => {
		this.setState({dataPoint: dataPoint, index: data.index})
	}

	handleOnMouseLeave = () => {
		this.setState({dataPoint: undefined, index: undefined})
	}
}

export default SentimentGraph;
