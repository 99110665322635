import React, { useRef, useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import qs from "qs"
import Alert from "react-bootstrap/Alert"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Loading from "Components/loading"
import UnauthenticatedSimpleLayout from "layouts/UnauthenticatedSimpleLayout" // This should live at a higher level
import { AuthAPI } from "services/auth"


const ForgotPassword = () => {
    const location = useLocation()
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
    const email = useRef<HTMLInputElement | null>(null)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
    const [isSent, setIsSent] = useState<boolean>(false)

    const sendRequest = useCallback(
        async (email: string) => {
            if (!isSubmitting && !isSent) {
                setIsSubmitting(true)
                try {
                    const response = await AuthAPI.forgotPassword(email)
                    if (response.status === 200) {
                        setIsSubmitting(false)
                        setIsSent(true)
                    } else {
                        setIsSubmitting(false)
                        setErrorMsg(
                            "There was an error submitting your request. Please try again or contact support."
                        )
                    }
                } catch (error) {
                    setIsSubmitting(false)
                    if (
                        error.response?.data.name === "LimitExceededException"
                    ) {
                        setErrorMsg(
                            "Too many password reset requests. Please try again after some time."
                        )
                    } else {
                        setErrorMsg(
                            "There was an error submitting your request. Please try again or contact support."
                        )
                    }
                }
            }
        },
        [isSubmitting, isSent]
    )

    useEffect(() => {
        if (
            queryParams.email &&
            queryParams.autosend &&
            (!isSent || !isSubmitting)
        ) {
            // requesting a reset from another page
            sendRequest(queryParams.email as string)
        }
    }, [queryParams?.email, queryParams?.autosend])

    const submitHandler = async (e: React.FormEvent) => {
        e.preventDefault()
        if (email.current) {
            sendRequest(email.current.value)
        }
    }

    return (
        <UnauthenticatedSimpleLayout>
            <Container fluid>
                <Row className="justify-content-center">
                    <Col md={4} xs={12}>
                        <div className="form__instructions mt-3">
                            {!isSent ? (
                                <div className="fw-bold">
                                    Enter your email and we'll send you a code
                                    to reset your password.
                                </div>
                            ) : (
                                <div>
                                    <h2>Email Sent</h2>
                                    <div>
                                        Check your email and open the link
                                        within to continue.
                                    </div>
                                </div>
                            )}
                        </div>
                        {!isSent && !queryParams?.email && (
                            <>
                                <Form onSubmit={submitHandler}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="email">
                                            Email
                                        </Form.Label>
                                        <Form.Control
                                            type="email"
                                            size="lg"
                                            id="email"
                                            ref={email}
                                            required
                                        />
                                    </Form.Group>
                                    {errorMsg && (
                                        <Alert key="danger" variant="danger">
                                            {errorMsg}
                                        </Alert>
                                    )}
                                    <div>
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            name="submit"
                                            disabled={isSubmitting}
                                        >
                                            Send password reset email
                                        </Button>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Col>
                </Row>
                {isSubmitting && <Loading>Processing request</Loading>}
            </Container>
        </UnauthenticatedSimpleLayout>
    )
}

export default ForgotPassword
