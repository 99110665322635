import { useCallback, useMemo, useState, Component, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import useAuth from "lib/auth/useAuth"
import useBrand from "lib/useBrand"
import styles from "./header.module.css"
import { User } from "../Models/user"
import { Brand } from "../Models/brand"
import { URLS } from "URLS"
import { Popover, ArrowContainer } from "react-tiny-popover"
import fallbackLogo from "assets/img/logo.png"

interface Props {}

const Header: React.FC<Props> = ({}: Props) => {
    const [isPopOpen, setIsPopOpen] = useState<boolean>(false)
    const [filterText, setFilterText] = useState<string>("")
    const { user, brands }: {user?: User, brands?: Brand[]} = useAuth()
    const { brand, setBrandId } = useBrand()
    const [filteredBrands, setFilteredBrands] = useState<Brand[]>(brands ? brands : [])
    const navigate = useNavigate()

    const onError = (e: any) => {
        e.target.onError = null
        e.target.src = fallbackLogo
    }

    let logo = (
        <img
            className={styles.logo}
            src={`${process.env.REACT_APP_IMAGE_URL}/brands/${brand?.id}/logo.jpg`}
            onError={onError}
        />
    )
    if (brand?.id == -1) {
        logo = (
            <img
                className={styles.logo}
                src={`${process.env.REACT_APP_IMAGE_URL}/westock/logo.png`}
            />
        )
    }

	useEffect(()=> {
		if (isPopOpen == true) {
            document.addEventListener("keydown", handleKeyboard)
        }
		return ()=> document.removeEventListener("keydown", handleKeyboard)
	}, [isPopOpen])

    const handleKeyboard = (e: any) => {
        e.preventDefault()
        setFilterText((f)=> {
			let newText = f
			let newBrands = brands ? brands : []
			const key = e.key
			if (key === "Backspace" || key === "Delete") {
				newText = newText.slice(0, -1)
			} else if (key.length == 1) {
				newText = newText + key
			}
			newText = newText.toLocaleUpperCase()
			newBrands = newBrands.filter((b) => {
				return b.name.toLocaleUpperCase().startsWith(newText)
			})
			setFilteredBrands(newBrands)
			return newText
		})
    }

    const togglePopUp = () => {
        const newPopOpen = !isPopOpen
        setFilterText("")
        setIsPopOpen(newPopOpen)
        setFilteredBrands(brands ? brands : [])
    }

    const handleClick = (brand: Brand) => {
        togglePopUp()
        setBrandId(brand.id)
    }

    const handleOnLogoutClick = () => {
        navigate(URLS.LOGOUT)
    }

    return (
        <div className={styles.header}>
            <div className={styles.titleContainer}>
                {isPopOpen ? (
                    <Popover
                        onClickOutside={(e:any) => {if (e.target.isConnected) {togglePopUp()} }}
                        isOpen={isPopOpen}
                        positions={["bottom"]}
						containerStyle={{zIndex: '3'}}
                        content={({ position, childRect, popoverRect }) => (
                            <ArrowContainer
                                position={position}
                                childRect={childRect}
                                popoverRect={popoverRect}
                                arrowColor={"rgb(224, 225, 227)"}
                                arrowSize={5}
                            >
                                <div className={styles.popContainer}>
                                    <table
                                        className={styles.dateSelectionTable}
                                    >
                                        <tbody>
                                            {filteredBrands.map((currBrand) => (
                                                <tr key={currBrand.id}>
                                                    <td onClick={() => handleClick(currBrand)}>
                                                        {currBrand.name}
                                                        {currBrand.id === brand?.id ? (
                                                            <img
                                                                className={styles.checkmark}
                                                                src={`${process.env.REACT_APP_DASH_IMAGE_URL}/checkmark.png`}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div key={filterText} className={styles.filterText}>
                                        {filterText}
                                    </div>
                                </div>
                            </ArrowContainer>
                        )}
                    >
                        <p
                            key={`${brand?.id}-open`}
                            onClick={togglePopUp}
                            className={styles.title}
                        >
                            {brand?.name} Dashboard
                            <img
                                className={styles.chevron}
                                src={`${process.env.REACT_APP_DASH_IMAGE_URL}/chevronLarge.png`}
                            />
                        </p>
                    </Popover>
                ) : (
                    <p
                        key={`${brand?.id}-closed`}
                        onClick={togglePopUp}
                        className={styles.title}
                    >
                        {brand?.name} Dashboard
                        <img
                            className={styles.chevron}
                            src={`${process.env.REACT_APP_DASH_IMAGE_URL}/chevronLarge.png`}
                        />
                    </p>
                )}
            </div>
            <div>
                <div className={styles.userContainer}>
                    <div className={styles.user}>
                        {user && `Hello, ${user.firstName} ${user.lastName}`}
                        <div className={styles.logout} onClick={handleOnLogoutClick}>
                            LOGOUT
                        </div>
                    </div>
                    {logo}
                </div>
            </div>
        </div>
    )
}

export default Header
