import { Component } from 'react';
import styles from './components.module.css'
import { User } from '../Models/user';
import ErrorModal from './errorModal';
import NetworkManager from '../Network/networkManager'
import Saving from './saving';

interface Props {
	callback?: () => void
}

interface State {
	saving: boolean
	error?: any
}

class TermsForm extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			saving: false
		}
	}

	render(){
		const { error, saving } = this.state
		const standAlone = this.props.callback == undefined

		if(saving == true) {
			return (
				<div className={styles.termsBackground}>
					<div className={styles.termsContainer}>
						<Saving />
					</div>
				</div>
			)
		} 

		return (
			<div className={styles.termsBackground}>
				{error && <ErrorModal isOpen={true} error={error} onDismiss={()=>{this.setState({error: undefined})}} />}
				<div className={styles.termsContainer}>
					<p className={styles.termsHeader}>WeStock Service Agreement</p>
					{!standAlone && <>
					Please read and accept the terms to continue onto your dashboard. If you do not accept you can close this window. 
					</>}
					<p className={styles.termsTitle}>Scope of Services</p>
					<ul>
						<li>Dashboard access</li>
						<li>Product request forms</li>
						<li>WeStock app placement</li>
						<li>Retail placement</li>
					</ul>

					<p className={styles.termsTitle}>Privacy Policy & Data Ownership</p>
					When you create a brand partner account, make a payment and or request services, WeStock must ask for information such as your name, email address, phone number, street address, usernames and passwords, feedback, information relating to your devices, age, gender, and language, bank account number, credit card details and other such financial information. Any information concerning the services rendered under this agreement or any information you advise us is confidential including your payment and personal information disclosed to WeStock shall be deemed confidential information. When a consumer accesses our services through the tools we provide you, our web servers automatically create records of their visit. These records typically include IP-address, access times, the sites linked from, pages visited, the links and features used, the content viewed or requested, browser or application type, language and other such information. WeStock owns all consumer data captured through the platform including but not limited to client data and is entitled to share that data with any retailer, distributor or third party client at WeStock’s sole discretion.
Retail Placement
					<br /> 
					<br />
					<p className={styles.termsTitle}>Retail Placement</p>
					WeStock does not guarantee placement in any retail partner. When you sign up for WeStock you are opting in to being discovered by our retail and buyer network. WeStock is not liable for any product or future business dealing outside of introducing you to the retailer when they have requested to stock the item initially.
					<br />
					<br />
					If you are an alcohol manufacturers or distributor using our SMS services, it is your responsibility to ensure they are in compliance with local, state, and federal laws and rules, WeStock is mindful of restrictions against suppliers providing prohibited things of value to retailers
					<br />
					<br />
					WeStock’s platform is funded by suppliers and not by retailers.
					<br />
					<br />
					WeStock does not partner with retailers or otherwise involve them in the platform.
					<br />
					<br />
					WeStock does not advertise for retailers, and it merely identifies to consumers retail locations where the supplier’s brands can be purchased.
					<br />
					<br />
					In all circumstances, WeStock identifies multiple retail locations and does not feature any retailer over another.
					<br />
					<br />
					<p className={styles.termsTitle}>Social</p>
					By entering into a partnership contract with WeStock, you will have automatically granted us permission to use a variety of assets, including images and phrases found on your brand's website and/or social media platforms, for marketing purposes related to the collaborative partnership.
					<br />
					<br />
					<p className={styles.termsTitle}>MISC</p>
					All Notices to WeStock should be submitted to cameron@westock.io. This Agreement is non-assignable. Disputes will first be handled by mediation and then, if unresolved, through Arbitration under the rules of the American Arbitration Association. If any portion of this Agreement is not enforceable, it will not affect the enforceability of the rest of the Agreement. Any modifications of this Agreement must be made in writing by mutual consent of the Parties. Jurisdiction and Venue shall be in the State of New Jersey, County of Bergen. WeStock cannot guarantee that using the Services will assure any increase or change in sales for Client. WeStock shall make a best effort to provide excellent Services to Client throughout the Term of this Agreement.
					{!standAlone && <button className={styles.termsAccept} onClick={this.handleAccept}>Accept to Continue</button>}
				</div>
			</div>
		)
	}

	handleAccept = async () => {
		this.setState({saving: true})
		try {
			await NetworkManager.acceptAgreement()
			if(this.props.callback) {
				this.props.callback()
			}
			this.setState({saving: false})
		} catch (error) {
			console.log(error)
			this.setState({error: error, saving: false})
		}
	}
}

export default TermsForm;
