/// <reference types="react-vis-types" />
import { Component } from "react"
import { useNavigate } from "react-router-dom"
import styles from "./tile.module.css"
import Tile, { TileProps, TileState } from "./tile"
import USChoropleth from "../../Components/usChoropleth"
import { URLS } from "URLS"

interface Props extends TileProps {
    data: { [key: string]: number }
    label: string
}

const MapTile: React.FC<Props> = ({ navigatePath, data }: Props) => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate("/reports/requests")
    }

    return (
        <Tile navigatePath={navigatePath}>
            <USChoropleth data={data} />
        </Tile>
    )
}

export default MapTile
