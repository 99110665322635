import styles from "./UnauthenticatedSimpleLayout.module.scss"
import Helmet from "react-helmet"
import Container from "react-bootstrap/Container"
import { ReactComponent as Logo } from "assets/img/westock_logo.svg"

interface LayoutProps {
    children: JSX.Element
}

const UnauthenticatedSimpleLayout = ({ children }: LayoutProps) => {
    return (
        <>
            <Helmet>
                <body className="public" />
            </Helmet>
            <div>
                <Container fluid className={styles.public_container__header}>
                    <Logo className={styles.logo} />
                </Container>
                <div className="public_container__children">{children}</div>
            </div>
        </>
    )
}

export default UnauthenticatedSimpleLayout
