import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import "./index.css"
import "styles/main.scss"
import App from "./App"
import { AuthProvider } from "lib/auth/useAuth" // Move Lower
import { BrandProvider } from "lib/useBrand" // Move Lower
import * as serviceWorker from "./serviceWorker"
import AppProvider from "context/App"
import * as Sentry from "@sentry/react"


if (process.env.REACT_APP_SENTRY === "true") {
    const westockRE = /^https:\/\/(.*?\.)?westock\.io/
    const replayRate =
        process.env.REACT_APP_SENTRY_REPLAY_RATE !== undefined
            ? parseFloat(process.env.REACT_APP_SENTRY_REPLAY_RATE)
            : 0.1
    const tracePropagationTargets = process.env.NODE_ENV === 'production' ? [westockRE] : ["localhost", westockRE]

    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_SENTRY_ENV,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: tracePropagationTargets,
            }),
            ...(process.env.REACT_APP_SENTRY_REPLAY === "true" ? [new Sentry.Replay()] : []),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: replayRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(
    <BrowserRouter>
        <AppProvider>
            <AuthProvider>
                <BrandProvider>
                    <App />
                </BrandProvider>
            </AuthProvider>
        </AppProvider>
    </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
