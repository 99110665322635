import { Component } from 'react';
import styles from './faqViews.module.css'
import { QASection, QA } from '../../Models/faqs';
import NetworkManager from '../../Network/networkManager'
import Loading from '../../Components/loading';

interface Props {
	section: QASection
}

interface State {
	questions?: QA[]
}

class QuestionsView extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			questions: undefined
		}
	}

	render(){
		const { questions } = this.state

		if(!questions) {
			return <Loading />
		}
		if(questions.length == 0) {
			return <>No FAQs for this section at this time. Come back later!</>
		}
		return (
			<div className={styles.report}>
				<p className={styles.sectionTitle}>{this.props.section.name}</p>
				<div className={styles.navContainer}>
				{		
				questions.map((question) => {
					return (
						<div className={styles.navLink} key={question.id + "M"}>
							• <a href={`#${question.id}`}>{ question.question}</a> 
							<br />
						</div>
					)
				})}
				</div>
				{
				questions.map((question) => {
					return (
					<div className={styles.section} key={question.id}> 
						<a id={question.id + ""}><p className={styles.title}>{question.question}</p></a>
						<br />
						{question.answer}
					</div>
					)
				})}
			</div>
		)
	}

	componentDidMount() {
		this.loadData()
	}

	async loadData() {
		try {
			let questions: QA[] = await NetworkManager.getQuestions(this.props.section.id)

			this.setState({
				questions: questions
			})
			
		} catch(error) {
			console.log(error)
		}	
	}

}

export default QuestionsView;
