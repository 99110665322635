import { Component } from 'react';
import styles from './tile.module.css';
import Tile, { TileProps, TileState } from './tile';
import { AnswerCount } from '../../Models/API/overviewAPI';
import SentimentGraph from '../../Components/sentimentGraph';

interface Props extends TileProps {
	answerData: AnswerCount[]
	label: string
}

interface State extends TileState {
	
}

class SentimentGraphTile extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
		}
	}

	render(){
		const data = this.props.answerData;
		if(data && data.length != 0) {
			return (
				<Tile navigatePath={this.props.navigatePath}>
					<div className={styles.graphContainer} >
						<SentimentGraph fontSize={14} answerData={data} />
					</div>
					<div className={styles.graphTitleContainer}>
						<p className={styles.graphTitle}> 
							{this.props.label}
						</p>
					</div>
				</Tile>
			)
		} else {
			return (
				<Tile navigatePath={this.props.navigatePath}>
					No Feedback
				</Tile>
			)
		}
		
	}

	componentDidMount () {
		setTimeout(() => {
		  window.requestAnimationFrame(() => {
			this.forceUpdate()
		  })
		}, 0)
	  }

	handleOnMouseOver = (value: any) => {
		this.setState({hoverValue: value})
	}

	handleOnMouseOut = () => {
		this.setState({hoverValue: undefined})
	}
}

export default SentimentGraphTile;