import { Component } from "react"
import { Routes, Route } from "react-router"
import { Navigate } from "react-router-dom"
import styles from "./opportunities.module.css"
import { Brand } from "../Models/brand"
import OpportunitiesMenu from "./opportunitiesMenu"
import Marketplace from "./Views/marketplace"
import { BrandDataViewProps } from "../Components/Base/brandDataView"
import { URLS } from "URLS"

interface Props extends BrandDataViewProps {}

interface State {}

class Opportunities extends Component<Props, State> {
    componentDidMount(): void {
        this.props.handleDateFilter(false)
    }

    render() {
        return (
            <div className={styles.opportunitiesContainer}>
                <OpportunitiesMenu />
                <div className={styles.contentContainer}>
                    <Routes>
                        <Route
                            path="marketplace"
                            element={<Marketplace brand={this.props.brand} />}
                        />
                        <Route
                            path="/"
                            index
                            element={<Navigate to={URLS.OPPORTUNITIES_MARKETPLACE} replace />}
                        />
                    </Routes>
                </div>
            </div>
        )
    }
}

export default Opportunities
