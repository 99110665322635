import * as React from 'react';
import { NavLink, NavLinkProps } from "react-router-dom"
import styles from './opportunities.module.css';

interface Props {

}

// TODO refactor for reuse
const StyledNavLink = ({to, children, className, ...props}:NavLinkProps) => {
	return (
		<NavLink
			{...props}
			className={({ isActive }) => isActive ? styles.selectedLink : styles.menuLink}
			to={to}
		>
			{children}
		</NavLink>
	)
}

const OpportunitiesMenu: React.FunctionComponent<Props> = (props) => {
	return (
		<nav className={styles.menuContainer}> 
			<StyledNavLink to="marketplace">MARKETPLACE</StyledNavLink>
		</nav>
	)
}

export default OpportunitiesMenu;
