import { Component } from 'react';
import styles from './faqs.module.css';
import { NavLink, NavLinkProps } from "react-router-dom"
import { QASection } from '../Models/faqs';
import Loading from '../Components/loading';
import NetworkManager from '../Network/networkManager'



interface Props {
	onSectionLoad: (sections: QASection[])=>void
}

interface State {
	sections: QASection[]
}


// XXX refactor for reuse
const StyledNavLink = ({to, children, className, ...props}:NavLinkProps) => {
	return (
		<NavLink
			{...props}
			className={({ isActive }) => isActive ? styles.selectedLink : styles.menuLink}
			to={to}
		>
			{children}
		</NavLink>
	)
}

class FormMenu extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			sections: []
		}
	}

	render(){
		let { sections }= this.state
		sections.sort((a,b) => a.position - b.position)
		
		return (
			<nav className={styles.menuContainer}> 
				{
					this.state.sections.length == 0 ? <Loading /> : this.state.sections.map((section) => {
						return <StyledNavLink to={`/faq/${section.name}`} key={section.id}>{section.name.toUpperCase()}</StyledNavLink>
					})
				}
			</nav>		
		)
	}

	componentDidMount() {
		this.loadData()
	}

	async loadData() {
		try {
			let sections: QASection[] = await NetworkManager.getQASections()
			this.setState({
				sections: sections
			})
			this.props.onSectionLoad(sections)
		} catch(error) {
			console.log(error)
		}	
	}


}

export default FormMenu;
