import { Component } from 'react';
import { Routes, Route } from "react-router"
import { redirect } from "react-router-dom"
import styles from './faqs.module.css'
import FaqsMenu from './faqsMenu';
import { QASection } from '../Models/faqs';
import QuestionsView from './Views/questionsView';

interface Props {
	
}

interface State {
	sections: QASection[]
}

class FAQs extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			sections: []
		}
	}

	render(){
		const { sections } = this.state

		return (
			<div className={styles.reportContainer}>
				<FaqsMenu onSectionLoad={this.handleOnSectionsLoad}/>
				<Routes>
					{sections.map((section) => {
						return (
							<Route path={section.name}
								element={
									<QuestionsView key={section.id} section={section} />
								}
							/>
						)
					})}
					
				</Routes>
			</div>		
		)
	}

	handleOnSectionsLoad = (sections: QASection[]) => {
		this.setState({sections: sections})
		if(sections.length > 0) {
			if(window.location.href.endsWith("/faqs")) {
				redirect(`/faqs/${sections[0].name}`)
			} else {
				setTimeout(()=>window.location.replace(window.location.href), 500)
			}
		}		
	}
}

export default FAQs;
