import { Component } from 'react';

interface Props {

}

interface State {

}

class Saving extends Component<Props, State> {
	render(){
		return (
			<div>
				Saving...
			</div>
		)
	}
}

export default Saving;
