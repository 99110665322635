import { useState } from "react"
import styles from "../Dashboard/navBar.module.css"
import { NavLink, NavLinkProps } from "react-router-dom"
import DateFilter from "../Components/dateFilter"
import Moment from "moment"
import networkManager from "../Network/networkManager"
import { DashboardRelease } from "../Models/faqs"
import { DateFilterRanges } from "../Models/enums"
import { URLS } from "URLS"
import useAuth from "lib/auth/useAuth"
import Badge from "react-bootstrap/Badge"

interface Props {
    showDateFilter: boolean
    dateFilter: DateFilterRanges
    beginDate: Moment.Moment | null
    endDate: Moment.Moment | null
    lifeTimeDate?: Moment.Moment
    release?: DashboardRelease
    notificationCount: number
    onDateChange: (start: Moment.Moment, end: Moment.Moment, filterRanges: DateFilterRanges) => void
}

const StyledNavLink = ({ to, children, className, ...props }: NavLinkProps) => {
    return (
        <NavLink
            {...props}
            className={({ isActive }) => (isActive ? styles.selectedLink : styles.navLink)}
            to={to}
        >
            {children}
        </NavLink>
    )
}

const AdminNavBar: React.FC<Props> = (props: Props) => {
    const { isAdmin } = useAuth()

    return (
        <div className={styles.navContainer}>
            <nav className={styles.navItemContainer}>
                <StyledNavLink to={URLS.DASHBOARD} end>
                    Overview
                </StyledNavLink>
                <StyledNavLink to={URLS.DATA}>Data</StyledNavLink>
                <StyledNavLink to={URLS.FORM}>Forms</StyledNavLink>
                <StyledNavLink to={URLS.NOTIFICATIONS}>
                    Notifications
                    {props.notificationCount > 0 ? (
                        <span className={styles.badge}>{props.notificationCount}</span>
                    ) : (
                        ""
                    )}
                </StyledNavLink>
                <a
                    className={styles.navLink}
                    href="https://westock.helpscoutdocs.com/"
                    target="_blank"
                    rel="noopener"
                >
                    FAQs
                </a>
                {process.env.REACT_APP_FF_PRODUCTS == "true" && (
                    <StyledNavLink to={URLS.PRODUCTS}>Products <Badge className="ms-1" pill>1</Badge></StyledNavLink>
                )}
                {process.env.REACT_APP_FF_STORE == "true" && (
                    <StyledNavLink to={URLS.STORES}>Stores</StyledNavLink>
                )}
                {process.env.REACT_APP_FF_AUDIENCE == "true" && (
                    <StyledNavLink to={URLS.AUDIENCE}>Audience</StyledNavLink>
                )}
                {process.env.REACT_APP_FF_CAMPAIGN_DASHBOARD == "true" && isAdmin && (
                    <StyledNavLink to={URLS.CAMPAIGN}>Campaigns</StyledNavLink>
                )}
                <StyledNavLink to={URLS.SETTINGS}>Settings</StyledNavLink>
            </nav>
            {props.showDateFilter && (
                <DateFilter
                    beginDate={props.beginDate}
                    endDate={props.endDate}
                    dateFilter={props.dateFilter}
                    onDateChange={props.onDateChange}
                    lifeTimeDate={props.lifeTimeDate}
                />
            )}
            {props.release && (
                <div className={styles.releaseContainer}>
                    <a href={props.release.link}>{props.release.displayText}</a>
                </div>
            )}
        </div>
    )
}

export default AdminNavBar
