import { Component, PropsWithChildren } from 'react';
import styles from './components.module.css'

interface Props extends PropsWithChildren {

}

interface State {

}

// Loads the chilren prop under the loading animation. Should normally be text.
class Loading extends Component<Props, State> {
	render(){
		return (
			<div className={styles.loader}>
				<div id={styles.loaderAnimation}>
					<div id={styles.box}></div>
					<div id={styles.hill}></div>
				</div>
				<div id={styles.loadingText}>{this.props.children ? this.props.children : 'Loading...'}</div>
			</div>
		)
	}
}

export default Loading;
