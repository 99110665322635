import React, { useEffect, useState, Component } from "react"
import { Routes, Route } from "react-router-dom"
import Header from "./header"
import NavBar from "./navBar"
import Overview from "../Overview/overview"
import { User } from "../Models/user"
import { Brand, BrandStatus } from "../Models/brand"
import useAuth from "lib/auth/useAuth"
import useBrand from "lib/useBrand"
import networkManager from "Network/networkManager"
import Loading from "Components/loading"
import Moment from "moment"
import FAQs from "../FAQs/faqs"
import NoAccess from "../NoAccess/noAccess"
import { DashboardRelease } from "../Models/faqs"
import { DateFilterRanges } from "../Models/enums"
import Opportunities from "../Opportunities/opportunities"
import { Logout } from "pages/Logout"
import { URLS } from "URLS"
import HelpScoutBeacon from "Components/HelpScout/HelpScoutBeacon"

const Notifications = React.lazy(() => import(/* webpackPrefetch: true */ "../Notifications/notifications"))
const ProductIndex = React.lazy(() => import(/* webpackPrefetch: true */ "pages/product/ProductIndex"))
const StoreIndex = React.lazy(() => import(/* webpackPrefetch: true */ "pages/store/StoreIndex"))
const AudienceIndex = React.lazy(() => import(/* webpackPrefetch: true */ "pages/audience/AudienceIndex"))
const Reports = React.lazy(() => import(/* webpackPrefetch: true */ "../Reports/reports"))
const Settings = React.lazy(() => import(/* webpackPrefetch: true */ "../Settings/settings"))
const Form = React.lazy(() => import(/* webpackPrefetch: true */ "../Form/form"))
const Campaigns = React.lazy(() => import(/* webpackPrefetch: true */ "pages/campaign/CampaignIndex"))

interface Props {
    user: User
    brands: Brand[]
    selectedBrand?: Brand
}

const Dashboard: React.FC<Props> = ({}: Props) => {
    const [beginDate, setBeginDate] = useState<Moment.Moment>(Moment().subtract("30", "days"))
    const [endDate, setEndDate] = useState<Moment.Moment>(Moment())
    const [dateFilterRanges, setDateFilterRanges] = useState<DateFilterRanges>(
        DateFilterRanges.last30
    )
    const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
    const [release, setDashboadRelease] = useState<DashboardRelease>() // TODO cache this somewhere

    const { user, setBrandNotificationsCount } = useAuth()
    const { brand } = useBrand()

    // TODO move this assertion upstream
    if (!user) {
        throw new Error(`ERROR: User reached Dashboard with no 'user' in context`)
    }
    if (!brand) {
        throw new Error(`ERROR: User reached Dashboard with no 'brand' in context`)
    }

    useEffect(() => {
        const getRelease = async () => {
            const response = await networkManager.getRelease()
            setDashboadRelease(response)
        }
        try {
            getRelease()
        } catch (err) {
            console.log("error fetching release", err)
        }
    }, [])

    const handleDateChange = (
        start: Moment.Moment,
        end: Moment.Moment,
        filterRanges: DateFilterRanges
    ) => {
        setBeginDate(start)
        setEndDate(end)
        setDateFilterRanges(filterRanges)
    }

    const handleNotificationRead = (count: number) => {
        if (brand) {
            setBrandNotificationsCount(brand.id, count)
        }
    }

    if (brand.status != BrandStatus.Active) {
        return (
            <div>
                <Header />
                <HelpScoutBeacon />
                <div className="content-container">
                    <Routes>
                        <Route path="/*" element={<NoAccess brand={brand}></NoAccess>} />
                    </Routes>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Header />
            <HelpScoutBeacon />
            <NavBar
                notificationCount={brand.notificationCount}
                showDateFilter={showDateFilter}
                release={release}
                beginDate={beginDate}
                endDate={endDate}
                dateFilter={dateFilterRanges}
                onDateChange={handleDateChange}
                lifeTimeDate={Moment(brand.dateAdded)}
            />
            <div className={"content-container"}>
                <React.Suspense fallback={<Loading />}>
                    <Routes>
                        <Route
                            path={URLS.DASHBOARD}
                            element={
                                <Overview
                                    brand={brand}
                                    beginDate={beginDate}
                                    endDate={endDate}
                                    handleDateFilter={setShowDateFilter}
                                />
                            }
                        />
                        <Route
                            path={`${URLS.DATA}/*`}
                            element={
                                <Reports
                                    brand={brand}
                                    beginDate={beginDate}
                                    endDate={endDate}
                                    handleDateFilter={setShowDateFilter}
                                />
                            }
                        />
                        <Route
                            path={`${URLS.FORM}/*`}
                            element={<Form brand={brand} handleDateFilter={setShowDateFilter} />}
                        />
                        <Route
                            path={`${URLS.OPPORTUNITIES}/*`}
                            element={
                                <Opportunities brand={brand} handleDateFilter={setShowDateFilter} />
                            }
                        />
                        <Route
                            path={`${URLS.NOTIFICATIONS}/*`}
                            element={
                                <Notifications
                                    handleNotificationRead={handleNotificationRead}
                                    brand={brand}
                                    user={user}
                                    handleDateFilter={setShowDateFilter}
                                />
                            }
                        />
                        <Route path={`${URLS.FAQS}/*`} element={<FAQs />} />
                        <Route path={`${URLS.PRODUCTS}/*`} element={<ProductIndex />} />
                        <Route path={`${URLS.STORES}/*`} element={<StoreIndex />} />
                        <Route path={`${URLS.AUDIENCE}/*`} element={<AudienceIndex />} />
                        <Route path={`${URLS.CAMPAIGN}/*`} element={<Campaigns />} />
                        <Route
                            path={`${URLS.SETTINGS}/*`}
                            element={<Settings brand={brand} handleDateFilter={setShowDateFilter} />}
                        />
                        <Route path={URLS.LOGOUT} element={<Logout />} />
                    </Routes>
                </React.Suspense>
            </div>
        </div>
    )
}

export default Dashboard
