export const URLS = {
    DASHBOARD: "/",
    DATA: "/data",
    FAQS: "/faqs",
    NOTIFICATIONS: "/notifications",
    SETTINGS: "/settings",
    SETTINGS_ANALYTICS: "/settings/analytics",
    SETTINGS_BRAND: "/settings/brand",
    SETTINGS_INTEGRATION: "/settings/integration",
    SETTINGS_PROFILE: "/settings/profile",
    SETTINGS_SUBSCRIPTION: "/settings/subscription",
    TERMS: "/terms",

    // Forms
    FORM: "/form",
    FORM_CAMPAIGN: "/form/campaign",
    FORM_GUIDE: "/form/formGuide",
    FORM_SOURCE: "/form/source",
    FORM_EDIT: "/form/edit",
    FORM_PRODUCTS: "/form/products",
    FORM_PROMO: "/form/promo",
    FORM_MANAGE_FEEDBACK: "/form/manageFeedback",
    FORM_LINKS: "/form/links",

    // OPPORTUNITIES
    OPPORTUNITIES: "/opportunities",
    OPPORTUNITIES_MARKETPLACE: "/opportunities/marketplace",

    // Admin
    MANAGE_DATA: "/manage-data",
    MANAGE_DATA_MANUFACTURER: "/manage-data/manufacturer",

    // Admin Stores
    ADMIN_STORES: "/admin/stores",
    ADMIN_MANUFACTURERS: "/admin/stores/manufacturers",
    ADMIN_HOLDING_COMPANIES: "/admin/stores/holding_companies",
    ADMIN_BANNERS: "/admin/stores/banners",

    // Admin Stats
    STATS: "/stats",
    STATS_USERS: "/stats/users",
    STATS_REQUESTS: "/stats/requests",
    STATS_BRAND: "/stats/brand",
    STATS_STORES: "/stats/stores",
    STATS_KEYWORDS: "/stats/keywords",
	STATS_REIMBURSEMENTS: "/stats/reimbursements",
	STATS_CAMPAIGNS: "/stats/campaigns",

    // Stores
    STORES: "/stores",
    STORES_IMPORT: "/stores/import",
    STORES_IMPORT_HISTORY: "/stores/import/history",
    STORES_IMPORT_HISTORY_DETAILS: (jobId: string) => `/stores/import/history/${jobId}`,
    STORES_EXPLORE: "/stores/explore",

    STORES_SEGMENT: "/stores/segment",
    STORES_MANAGE_SEGMENTS: "/stores/manageSegments",

    // Campaigns
    CAMPAIGN: "/campaign",
    CAMPAIGN_EDITOR: (campaignId: string) => `/campaign/editor/${campaignId ? campaignId : ""}`,
    // Auth
    FORCE_PASSWORD_CHANGE: "/force_password_change",
    FORGOT_PASSWORD: "/forgot_password",
    FORGOT_PASSWORD_CONFIRM: "/forgot_password/confirm",
    LOGIN: "/login",
    LOGOUT: "/logout",

    // Audience
    AUDIENCE: "/audience",
    AUDIENCE_CONTACTS: "/audience/contacts",
    AUDIENCE_CONTACTS_IMPORT: "/audience/contacts/import",
    AUDIENCE_CONTACTS_IMPORT_KLAVIYO: "/audience/contacts/import/klaviyo",
    AUDIENCE_CONTACTS_IMPORT_HISTORY: "/audience/contacts/import/history",
    AUDIENCE_CONTACTS_IMPORT_HISTORY_DETAILS: (jobId: string) =>
        `/audience/contacts/import/history/${jobId}`,
    AUDIENCE_LOCATIONS: "/audience/locations",
    AUDIENCE_FEEDBACK: "/audience/feedback",

    AUDIENCE_PROSPECT: "/audience/prospect",
    AUDIENCE_MANAGE_REPORTS: "/audience/manageReports",

    AUDIENCE_CUSTOMER_LISTS: "/audience/customerLists",
    AUDIENCE_CUSTOMER_LISTS_DETAILS: (listId: string) => `/audience/customerLists/${listId}`,

    // Products
    PRODUCTS: "/products",
    PRODUCTS_PRICING: "/products/pricing",
    PRODUCTS_PRICING_EXPLORER: "/products/pricing/explorer",
    PRODUCTS_GROUPS: "/products/groups",
    PRODUCTS_DETAILS: (productId: string) => `/products/${productId}/details`,
    PRODUCTS_EDIT: (productId: string) => `/products/${productId}/edit`,

    // Reports
    REPORT_GUIDE: "/data/reportGuide",
    REPORT_REQUESTS: "/data/requests",
    LEADERBOARDS: "/data/leaderboards",
	CAMPAIGN_DATA: "/data/campaigns",

    // External
    SIGNUP_URL: "https://www.westock.io/pricing",
}

export const URLS_UNPROTECTED = [
    URLS.FORCE_PASSWORD_CHANGE,
    URLS.FORGOT_PASSWORD,
    URLS.FORGOT_PASSWORD_CONFIRM,
    URLS.LOGIN,
    URLS.TERMS,
]

export default URLS
