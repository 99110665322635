import Axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { ApiAdminOverview, ApiBrandOverview } from "../Models/API/overviewAPI";
import { ApiRequestReport, ApiProductReport, ApiStoreReport, ApiTagReport, ApiStateReport, ApiSourceReport, ApiStoreProductsReport, ApiBannerReport } from '../Models/API/reports'
import { Moment } from "moment";
import { ApiManageData, ApiResource } from "../Models/API/manageData";
import { QASection, QA, DashboardRelease } from "../Models/faqs";
import { Brand, PromoCode } from "../Models/brand";
import { Banner } from "../Models/store";
import { BrandNotification, BrandRequestAlert } from "../Models/notification";
import { BrandForm, BrandFormDetails, BrandFormList } from "../Models/form";
import { DefaultPayment, LoginApi, Proration } from "../Models/user";
import { OrganizationReimbursementCounts, ReimbursementCounts, ReimbursementDetails } from "Models/reimbursement";
import { CampaignOverview } from "Models/API/campaignStats";


let storeAbortController: AbortController;
let bannerAbortToken: AbortController;

const _createAxiosInstance = () => {
	return Axios.create({
		baseURL: `${process.env.REACT_APP_API_BASE_URL!}`,
		timeout: 60000,
		withCredentials: true,
	});
}

class NetworkManager {
	private axiosInstance: AxiosInstance
	constructor(){
		this.axiosInstance = _createAxiosInstance()

		this.axiosInstance.interceptors.response.use((response:AxiosResponse) => {
			return response
		}, async function(error) {
			const originalRequest = error.config
			if (!originalRequest._retry && (error.response.status === 401 && error.response.data.message === "Token Expired")) {
				originalRequest._retry = true
				let axiosInstance = _createAxiosInstance()
				// refresh token
				try {
					const refreshResponse = await axiosInstance.post('/auth/refresh', {})
					return axiosInstance(originalRequest)
				} catch (refreshError) {
					return Promise.reject(refreshError)
				}
			}
			return Promise.reject(error)
		})
	}

	// DEPRECATE
	async getUser(): Promise<LoginApi|null> {
			return new Promise(async (resolve, reject) => {
				(async () => {
					try {
						resolve((await this.axiosInstance.get('/user')).data)
					} catch(error) {
						console.log(error)
						reject('No User Logged In')
					}				
				})()
			})
	}

	async getBrandOverview(brandID: number, beginDate: Moment, endDate: Moment): Promise<ApiBrandOverview> {
		const result: ApiBrandOverview = (await this.axiosInstance.get("/overview", {params: {
			brand_id: brandID,
			begin_date: beginDate.format("YYYY-MM-DD"),
			end_date: endDate.format("YYYY-MM-DD")
		}})).data
		return result
	}

	async getRequestReport(brandID: number, beginDate: Moment, endDate: Moment): Promise<ApiRequestReport> {
		const result: ApiRequestReport = (await this.axiosInstance.get("/report/request", {params: {
			brand_id: brandID,
			begin_date: beginDate.format("YYYY-MM-DD"),
			end_date: endDate.format("YYYY-MM-DD")
		}})).data
		return result
	}

	async getSourceReport(brandID: number, beginDate: Moment, endDate: Moment): Promise<ApiSourceReport> {
		const result: ApiSourceReport = (await this.axiosInstance.get("/report/source", {params: {
			brand_id: brandID,
			begin_date: beginDate.format("YYYY-MM-DD"),
			end_date: endDate.format("YYYY-MM-DD")
		}})).data
		return result
	}

	async getProductReport(brandID: number, beginDate: Moment, endDate: Moment): Promise<ApiProductReport> {
		const result: ApiProductReport = (await this.axiosInstance.get("/report/product", {params: {
			brand_id: brandID,
			begin_date: beginDate.format("YYYY-MM-DD"),
			end_date: endDate.format("YYYY-MM-DD")
		}})).data
		return result
	}


	async getStoreReport(brandID: number, beginDate: Moment, endDate: Moment, limit: number, offset: number, keyword: string, orderBy: string, state: string = '', bannerIDs: string = ''): Promise<ApiStoreReport> {
		if(storeAbortController) {
			storeAbortController.abort()
		}
		storeAbortController = new AbortController()
		const result: ApiStoreReport = (await this.axiosInstance.get("/report/store", {params: {
			brand_id: brandID,
			begin_date: beginDate.format("YYYY-MM-DD"),
			end_date: endDate.format("YYYY-MM-DD"),
			limit: limit,
			offset: offset,
			keyword: keyword,
			order_by: orderBy,
			state: state,
			bannerIDs: bannerIDs
		}, signal: storeAbortController.signal})).data
		return result
	}

	async getBannerReport(brandID: number, beginDate: Moment, endDate: Moment, limit: number, offset: number, keyword: string, orderBy: string, state: string = ''): Promise<ApiBannerReport> {
		if(bannerAbortToken) {
			bannerAbortToken.abort()
		}
		bannerAbortToken = new AbortController()
		const result: ApiBannerReport = (await this.axiosInstance.get("/report/banner", {params: {
			brand_id: brandID,
			begin_date: beginDate.format("YYYY-MM-DD"),
			end_date: endDate.format("YYYY-MM-DD"),
			limit: limit,
			offset: offset,
			keyword: keyword,
			order_by: orderBy,
			state: state
		}, signal: bannerAbortToken.signal})).data
		return result
	}

	async getStoreProducts(brandID: number, storeID: number, beginDate: Moment, endDate: Moment): Promise<ApiStoreProductsReport> {
		const result: ApiStoreProductsReport = (await this.axiosInstance.get("/report/storeProducts", {params: {
			brand_id: brandID,
			store_id: storeID,
			begin_date: beginDate.format("YYYY-MM-DD"),
			end_date: endDate.format("YYYY-MM-DD")
		}})).data
		return result
	}
	
	async getTagReport(brandID: number, beginDate: Moment, endDate: Moment): Promise<ApiTagReport> {
		const result: ApiTagReport = (await this.axiosInstance.get("/report/tag", {params: {
			brand_id: brandID,
			begin_date: beginDate.format("YYYY-MM-DD"),
			end_date: endDate.format("YYYY-MM-DD")
		}})).data
		return result
	}

	async getStateReport(brandID: number, beginDate: Moment, endDate: Moment): Promise<ApiStateReport> {
		const result: ApiStateReport = (await this.axiosInstance.get("/report/state", {params: {
			brand_id: brandID,
			begin_date: beginDate.format("YYYY-MM-DD"),
			end_date: endDate.format("YYYY-MM-DD")
		}})).data
		return result
	}

	async getBannerInfo(bannerID: number): Promise<Banner> {
		const result: Banner = (await this.axiosInstance.get(`/report/bannerInfo/${bannerID}`)).data
		return result
	}

	async saveReportAnalytic(brandID: number, report: string): Promise<void> {
		await this.axiosInstance.put("/report/analytic",
			{
				brandID: brandID,
				report: report
			}
		)
	}

	async getQASections(): Promise<QASection[]> {
		const result: QASection[] = (await this.axiosInstance.get('/faqs/qaSection', {
			params: {
				
		}})).data
		return result
	}

	async getQuestions(sectionID: number): Promise<QA[]> {
		const result: QA[] = (await this.axiosInstance.get('/faqs/question', {
			params: {
				sectionID: sectionID
		}})).data
		return result
	}

	async getBrandInfo(brandID: number): Promise<Brand[]> {
		const result: Brand[] = (await this.axiosInstance.get('/settings/brand', {
			params: {
				brandID: brandID
		}})).data
		return result
	}

	async changeSubscription(brandID: number): Promise<any> {
		const result = (await this.axiosInstance.get('/stripe/createCustomerPortalSession', {
			params: {
				brandID: brandID
			}
		})).data
		return result
	}

	async setNewSubscription(brandID: number, newTier: number) {
		await this.axiosInstance.get('/stripe/applyNewSubscription', {
			params: {
				brandID: brandID,
				newTier: newTier
			}
		})
	}

	async getProration(brandID: number, newTier: number): Promise<Proration> {
		const result = (await this.axiosInstance.get('/stripe/getProration', {
			params: {
				brandID: brandID,
				newTier: newTier
			}
		})).data
		return result
	}

	async getDefaultPayment(brandID: number): Promise<DefaultPayment> {
		const result = (await this.axiosInstance.get('/stripe/getCustomerDefaultPayment', {
			params: {
				brandID: brandID
			}
		})).data
		return result
	}

	async saveBrandInfo(brandID: number, manufacturerName: string, brandName: string, brandDescription: string, website: string, contactEmail: string, hide: boolean, storeLocatorLink?: string): Promise<void> {
		const result = (await this.axiosInstance.put('/settings/brand', {
				brandID: brandID,
				manufacturerName: manufacturerName,
				brandName: brandName,
				brandDescription: brandDescription,
				website: website,
				contactEmail: contactEmail,
				hide: hide,
				storeLocatorLink: storeLocatorLink
			})).data
	}

	async saveProfile(userID: number, firstName: string, lastName: string, receiveEmailNotifications: boolean): Promise<void> {
		const result = (await this.axiosInstance.put('/settings/profile', {
			userID: userID,
			firstName: firstName,
			lastName: lastName,
			receiveEmailNotifications: receiveEmailNotifications
		})).data
	}

	async getOrgAnalytics(orgId: number): Promise<{metaPixelId?: string}> {
		return (
			await this.axiosInstance.get('/settings/analytics',
				{params: {orgId}}
			)
		).data
	}

	async saveOrgAnalytics(orgId: number, analytics: {metaPixelId: string}): Promise<void> {
		return (await this.axiosInstance.put('/settings/analytics', {orgId, analytics})).data
	}

	// Settings: Integration

	async getOrgIntegrationSettings(orgId: number): Promise<{klaviyo?: string}> {
		return (
			await this.axiosInstance.get('/settings/integrations',
				{params: {orgId}}
			)
		).data
	}

	async saveOrgIntegrationSettings(orgId: number, integrations: {klaviyo?: string}): Promise<void> {
		return (await this.axiosInstance.put('/settings/integrations', {orgId, integrations})).data
	}

	// ---------------------

	async acceptAgreement(): Promise<void> {
		await this.axiosInstance.post('/settings/acceptAgreement')
	}

	async getRelease(): Promise<DashboardRelease> {
		return (await this.axiosInstance.get('/data/release')).data
	}

	async getDefaultPromoCode(brandID: number): Promise<PromoCode|undefined> {
		const result: PromoCode = (await this.axiosInstance.get(`/data/promoCode/default`, {
			params: {
				brandID: brandID
		}})).data
		return result
	}

	async setDefaultPromoCode(brandID: number, promoCodeID: number): Promise<PromoCode|undefined> {
		const result: PromoCode = (await this.axiosInstance.put(`/data/promoCode/default`, {
			params: {
				brandID: brandID,
				promoCodeID: promoCodeID
		}})).data
		return result
	}

	async getBrandForms(brandID: number, active: boolean, limit?: number, offset?: number, keyword?: string, orderBy?: string): Promise<BrandForm[]> {
		const result: BrandForm[] = (await this.axiosInstance.get('/data/form', {
			params: {
				brandID: brandID,
				active: active,
				limit: limit,
				offset: offset,
				keyword: keyword,
				orderBy: orderBy
			}
		})).data
		return result
	}

	async getBrandFormDetails(brandID: number, formID: number): Promise<BrandFormDetails> {
		const results = (await this.axiosInstance.get(`/data/brand/${brandID}/form/${formID}/details`)).data
		return results
	}

	async setDefaultForm(brandID: number, formID: number): Promise<void> {
		await this.axiosInstance.put(`/data/brand/${brandID}/form/default/${formID}`)
	}

	async getDefaultForm(brandID: number): Promise<BrandFormList> {
		return (await this.axiosInstance.get(`/data/brand/${brandID}/form/default`)).data
	}

	async getBrandFormList(brandID: number): Promise<BrandFormList[]> {
		return (await this.axiosInstance.get(`/data/brand/${brandID}/form/list`)).data
	}

	async completeBrandAnswerGroup(answerGroupID: number, brandID: number): Promise<void> {
		return await this.axiosInstance.put(`/data/brand/${brandID}/answerGroup/${answerGroupID}/completed`)
	}

	async deleteBrandAnswerGroup(answerGroupID: number, brandID: number): Promise<void> {
		return await this.axiosInstance.delete(`/data/brand/${brandID}/answerGroup/${answerGroupID}`)
	}
	// *************** BRAND ****************

	async brandDataGet<A extends ApiResource, T extends ApiManageData<A>>(brandID: number, type: string, limit?: number, offset?: number, keyword?: string, orderBy?: string, additionalParams?: {}): Promise<T> {
		const result: T = (await this.axiosInstance.get(`/data/${type}`, {
			params: {
				brandID: brandID,
				limit: limit,
				offset: offset,
				keyword: keyword,
				orderBy: orderBy,
				...additionalParams
		}})).data
		return result
	}

	async orgDataGet<A extends ApiResource, T extends ApiManageData<A>>(orgID: number, type: string, limit?: number, offset?: number, keyword?: string, orderBy?: string, additionalParams?: {}): Promise<T> {
		const result: T = (await this.axiosInstance.get(`/data/${type}`, {
			params: {
				orgID: orgID,
				limit: limit,
				offset: offset,
				keyword: keyword,
				orderBy: orderBy,
				...additionalParams
		}})).data
		return result
	}

	async dataGet<A extends ApiResource, T extends ApiManageData<A>>(type: string, limit?: number, offset?: number, keyword?: string, orderBy?: string): Promise<T> {
		const result: T = (await this.axiosInstance.get(`/data/${type}`, {
			params: {
				limit: limit,
				offset: offset,
				keyword: keyword,
				orderBy: orderBy
		}})).data
		return result
	}

	async brandDataAdd(brandID: number, type: string, params: any): Promise<void> {
		let newParams = this.checkForBooleans(params)
		newParams = this.checkForNulls(newParams)
		const data = new FormData()
		Object.entries(newParams).forEach((v: [string, any]) => {
			if(v[1] != null && v[1] != 'null' && typeof v[1] !== 'undefined') {
				if(Array.isArray(v[1]) && v[1].length > 0 && (typeof v[1][0] === 'object')) {
					data.append(v[0], JSON.stringify(v[1]))
				} else {
					data.append(v[0], v[1])
				}
			}
		})
		if(!data.has('brandID')) {
			data.append('brandID', brandID + "")
		}
		await this.axiosInstance.post(`/data/${type}`, data)
	}

	async orgDataAdd(orgID: number, type: string, params: any): Promise<void> {
		let newParams = this.checkForBooleans(params)
		newParams = this.checkForNulls(newParams)
		const data = new FormData()
		Object.entries(newParams).forEach((v: [string, any]) => {
			if(v[1] != null && v[1] != 'null' && typeof v[1] !== 'undefined') {
				if(Array.isArray(v[1]) && v[1].length > 0 && (typeof v[1][0] === 'object')) {
					data.append(v[0], JSON.stringify(v[1]))
				} else {
					data.append(v[0], v[1])
				}
			}
		})
		if(!data.has('orgId')) {
			data.append('orgId', orgID + "")
		}
		await this.axiosInstance.post(`/data/${type}`, data)
	}

	async brandDataEdit(brandID: number, type: string, params: any): Promise<void> {
		let newParams = this.checkForBooleans(params)
		newParams = this.checkForNulls(newParams)
		const data = new FormData()
		Object.entries(newParams).forEach((v: [string, any]) => {
			if(v[1] != null && v[1] != 'null' && typeof v[1] !== 'undefined') {
				if(Array.isArray(v[1]) && v[1].length > 0 && (typeof v[1][0] === 'object')) {
					data.append(v[0], JSON.stringify(v[1]))				
				} else {
					data.append(v[0], v[1])
				}
			}
		})
		if(!data.has('brandID')) {
			data.append('brandID', brandID + "")
		}
		await this.axiosInstance.put(`/data/${type}`, data)
	}

	async orgDataEdit(orgId: number, type: string, params: any): Promise<void> {
		let newParams = this.checkForBooleans(params)
		newParams = this.checkForNulls(newParams)
		const data = new FormData()
		Object.entries(newParams).forEach((v: [string, any]) => {
			if(v[1] != null && v[1] != 'null' && typeof v[1] !== 'undefined') {
				if(Array.isArray(v[1]) && v[1].length > 0 && (typeof v[1][0] === 'object')) {
					data.append(v[0], JSON.stringify(v[1]))				
				} else {
					data.append(v[0], v[1])
				}
			}
		})
		if(!data.has('orgId')) {
			data.append('orgId', orgId + "")
		}
		await this.axiosInstance.put(`/data/${type}`, data)
	}

	async brandDataDelete(type: string, id: number, brandID?: number): Promise<void> {
		const result: Brand[] = (await this.axiosInstance.delete(`/data/${type}`, {
			params: {
				id: id,
				brandID: brandID
			}
		})).data
	}

	async orgDataDelete(type: string, id: number, orgId?: number): Promise<void> {
		const result: Brand[] = (await this.axiosInstance.delete(`/data/${type}`, {
			params: {
				id: id,
				orgId: orgId
			}
		})).data
	}

	// *************** NOTIFICATIONS ****************

	async getNotifications(brandID: number): Promise<BrandNotification[]> {
		const result: BrandNotification[] = (await this.axiosInstance.get(`/notifications/brand/${brandID}`)).data
		return result
	}  

	async readNotification(brandNotificationID: number): Promise<void> {
		await this.axiosInstance.put(`/notifications/read/${brandNotificationID}`)
		return 
	}

	async deleteNotification(brandNotificationID: number): Promise<void> {
		await this.axiosInstance.delete(`/notifications/${brandNotificationID}`)
		return 
	}

	async restoreNotification(brandNotificationID: number): Promise<void> {
		await this.axiosInstance.put(`/notifications/restore/${brandNotificationID}`)
		return 
	}

	async getDeletedNotifications(brandID: number): Promise<BrandNotification[]> {
		const result: BrandNotification[] = (await this.axiosInstance.get(`/notifications/deleted/brand/${brandID}`)).data
		return result
	}

	async getAlerts(brandID: number): Promise<BrandRequestAlert[]> {
		const result: BrandRequestAlert[] = (await this.axiosInstance.get(`/alerts/brand/${brandID}`)).data
		return result
	}

	async editAlerts(alertID: number): Promise<void> {
		await this.axiosInstance.get(`/alerts/${alertID}`)
	}

	async createAlerts(brandID: number): Promise<void> {
		await this.axiosInstance.get(`/alerts/brand/${brandID}`)
	}

	async deleteAlerts(alertID: number): Promise<void> {
		await this.axiosInstance.delete(`/alerts/${alertID}`)
	}  

	// *************** ADMIN ****************

	async getAdminOverview(beginDate: Moment, endDate: Moment): Promise<ApiAdminOverview> {
		const result: ApiAdminOverview = (await this.axiosInstance.get('/admin/overview', {
			params: {
				beginDate: beginDate.format("YYYY-MM-DD"),
				endDate: endDate.format("YYYY-MM-DD")
			}
		})).data
		return result
	}

	async adminResendUserCredentials(userID: number): Promise<void> {
		const data = new FormData()
		data.append('id', userID + '')
		await this.axiosInstance.post('/admin/data/user/resendCredentials', data)
	}
 
	async adminDataGet<A extends ApiResource, T extends ApiManageData<A>>(type: string, limit?: number, offset?: number, keyword?: string, orderBy?: string): Promise<T> {
		const result: T = (await this.axiosInstance.get(`/admin/data/${type}`, {
			params: {
				limit: limit,
				offset: offset,
				keyword: keyword,
				orderBy: orderBy
		}})).data
		return result
	}

	async adminDataAdd(type: string, params: any): Promise<void> {
		let newParams = this.checkForBooleans(params)
		newParams = this.checkForNulls(newParams)
		const data = new FormData()
		Object.entries(newParams).forEach((v: [string, any]) => {
			if(v[1] != null && v[1] != 'null' && typeof v[1] !== 'undefined') {
				data.append(v[0], v[1])
			}
		})
		await this.axiosInstance.post(`/admin/data/${type}`, data)
	}

	async adminDataEdit(type: string, params: any): Promise<void> {
		let newParams = this.checkForBooleans(params)
		newParams = this.checkForNulls(newParams)
		const data = new FormData()
		Object.entries(newParams).forEach((v: [string, any]) => {
			if(v[1] != null && v[1] != 'null' && typeof v[1] !== 'undefined') {
				data.append(v[0], v[1])
			}
		})
		await this.axiosInstance.put(`/admin/data/${type}`, data)
	}

	async adminDataDelete(type: string, id: number): Promise<void> {
		const result: Brand[] = (await this.axiosInstance.delete(`/admin/data/${type}`, {
			params: {
				id: id
			}
		})).data
	}

	// *************** ADMIN STATS ****************

	async adminStatsGet<T extends ApiResource>(type: string, beginDate: Moment, endDate: Moment, otherParams?: any): Promise<T> {
		const result: T = (await this.axiosInstance.get(`/admin/stats/${type}`, {
			params: {
				beginDate: beginDate.format("YYYY-MM-DD"),
				endDate: endDate.format("YYYY-MM-DD"),
				...otherParams
		}})).data
		return result
	}

	// *************** ADMIN REIMBURSEMENTS ****************

	async adminReimbursementGet<A extends ApiResource, T extends ApiManageData<A>>(type: string, limit?: number, offset?: number, keyword?: string, orderBy?: string, orgID?: number, status?: string, accountType?: string): Promise<T> {
		const result: T = (await this.axiosInstance.get(`/admin/stats/${type}`, {
			params: {
				limit: limit,
				offset: offset,
				keyword: keyword,
				orderBy: orderBy,
				orgID: orgID,
				status: status,
				accountType: accountType
		}})).data
		return result
	}

	async adminReimbursmentCountsGet(): Promise<ReimbursementCounts> {
		const result: ReimbursementCounts = (await this.axiosInstance.get(`/admin/stats/reimbursement/brand`)).data
		return result
	}

	async adminReimbursmentGetDetail(reimbursementID?: number, timeStamp?: string, limit?: number, offset?: number, keyword?: string, orderBy?: string, orgID?: number, status?: string, accountType?: string): Promise<ReimbursementDetails> {
		const result: ReimbursementDetails = (await this.axiosInstance.get(`/admin/stats/reimbursement/detail`, {
			params: {
				timeStamp: timeStamp,
				limit: limit,
				offset: offset,
				keyword: keyword,
				orderBy: orderBy,
				orgID: orgID,
				status: status,
				accountType: accountType,
				reimbursementID: reimbursementID
		}})).data
		return result
	}

	async adminReimbursementUpdateDetails(reimbursementID: number, status: string, save: boolean, note?: string, product?: string, store?: string, transactionAmount?: number, transactionID?: string): Promise<void> {
		await this.axiosInstance.put(`/admin/stats/reimbursement/${reimbursementID}`, {
			status: status,
			note: note,
			product: product,
			store: store,
			transactionAmount: transactionAmount,
			transactionID: transactionID,
			save: save
		})
	}

	async adminReimbursementUnlockDetails(reimbursementID: number): Promise<void> {
		await this.axiosInstance.post(`/admin/stats/reimbursement/${reimbursementID}/unlock`)
	}
	// *************** UTILITY ****************

	checkForBooleans(params: any): any {
		let tempP = { ...params }
		Object.keys(params).forEach((key)=> {
			if (params[key] == 'true' || params[key] == 'True') {
				tempP[key] = true
			} else if (params[key] == 'false' || params[key] == 'False') {
				tempP[key] = false
			}
		})
		return tempP
	}

	checkForNulls(params: any): any {
		let tempP = { ...params }
		Object.keys(params).forEach((key)=> {
			if (params[key] === '') {
				tempP[key] = null
			}
		})
		return tempP
	}
}

export default new NetworkManager();
