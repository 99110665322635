import { Moment } from 'moment';
import BrandView, { BrandDataViewProps } from './brandDataView';


export interface FilterableBrandDataViewProps extends BrandDataViewProps {
	beginDate: Moment
	endDate: Moment
}

abstract class FilterableBrandDataView<P extends FilterableBrandDataViewProps, S> extends BrandView<P, S> {
	componentDidMount(): void {
		super.componentDidMount()

	}
	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any): void {
		const { brand, beginDate, endDate } = this.props
		if(!beginDate.isSame(prevProps.beginDate) || !endDate.isSame(prevProps.endDate) || brand.id != prevProps.brand.id ) {
			this.loadData()
		}
	}
}

export default FilterableBrandDataView;