import { Component } from 'react';
import styles from './opportunitieViews.module.css'
import { Brand } from '../../Models/brand';

interface Props {
	brand: Brand
}

interface State {
}

class Marketplace extends Component<Props, State> {
	render(){
		return (
			<div className={styles.marketplaceContainer}>
				<div className={styles.marketplaceSection}>
					<h1 className={styles.title}>Simera</h1>
					<img className={styles.marketplaceLogo} src='/images/marketplace/SimeraLogo.png'></img>
					<p className={styles.description}>Simera uses technology, tactical skill development and a streamlined hiring process to help companies fill roles, quickly. Hire Latin American remote-work professionals with function-specific skills and industry familiarity at record speed and value.</p>
					<div className={styles.specialOffer}>WeStock Offer:</div>
					<p className={styles.description}>WeStock is partnering with Simera: the first two month's cost will be complimentary.</p>
					<a className={styles.applyButton} href={`mailto:cameron@westock.io?subject=${this.props.brand.name} <> Simera&body=I want to take advantage of this offer.`}>APPLY NOW</a>
				</div>
				<div className={styles.marketplaceSection}>
					<h1 className={styles.title}>Trax</h1>
					<img className={styles.marketplaceLogo} src='/images/marketplace/TraxLogo.png'></img>
					<p className={styles.description}>Trax Merchandising actively and intelligently flexes to your needs at speed. Trax Dynamic Merchandising scales effortlessly, shelf issues are identified in real time, and merchandisers are mobilized in days, not weeks.</p>
					<div className={styles.specialOffer}>WeStock Offer:</div>
					<p className={styles.description}>10% discount on visits cost (project needs to be over 300 visits, only on their first project)</p>
					<a className={styles.applyButton} href={`mailto:cameron@westock.io?subject=${this.props.brand.name} <> Trax&body=I want to take advantage of this offer.`}>APPLY NOW</a>
				</div>
				<div className={styles.marketplaceSection}>
					<h1 className={styles.title}>Where To Buy</h1>
					<img className={styles.marketplaceLogo} src='/images/marketplace/WhereToBuyLogo.png'></img>
					<p className={styles.description}>Where to Buy is the modern, data-rich store locator that provides location availability from over 200 grocers across the U.S. & Canada, including drive-to-cart features, online integration, unlimited UPCs, and more.</p>
					<div className={styles.specialOffer}>WeStock Offer:</div>
					<p className={styles.description}>One month free</p>
					<a className={styles.applyButton} href={`mailto:cameron@westock.io?subject=${this.props.brand.name} <> Where To Buy&body=I want to take advantage of this offer.`}>APPLY NOW</a>
				</div>
				<div className={styles.marketplaceSection}>
					<h1 className={styles.title}>AdAdapted</h1>
					<img className={styles.marketplaceLogo} src='/images/marketplace/AdAdaptedLogo.png'></img>
					<p className={styles.description}>Now, it's easier than ever to reach the consumers you've been seeking. AdAdapted Direct helps brands to drive velocity, increase distribution, and build loyalty by staying top of mind with Shopping List Marketing - the smarter way to find & influence shoppers. You manage your ads through our easy-to-use self-service platform and we power your success by activating 1P data from our private audience of 110M+ verified grocery shoppers. Get on the List, Get in the Cart, with AdAdapted Direct.</p>
					<div className={styles.specialOffer}>WeStock Offer:</div>
					<p className={styles.description}>$200 towards your first campaign, no minimum commitment is necessary</p>
					<a className={styles.applyButton} href={`mailto:cameron@westock.io?subject=${this.props.brand.name} <> AdAdapted&body=I want to take advantage of this offer.`}>APPLY NOW</a>
				</div>
				<div className={styles.marketplaceSection}>
					<h1 className={styles.title}>Kickfurther</h1>
					<img className={styles.marketplaceLogo} src='/images/marketplace/KickfurtherLogo.png'></img>
					<p className={styles.description}>Kickfurther provides capital for high-growth ecommerce, wholesale, or multi-channel sales brands that order $20,000 to $5MM of inventory per year. Kickfurther funding is not debt on the balance sheet and is non-dilutive, so it can co-exist with existing other financing solutions and helps entrepreneurs maintain control of their companies. All deals have custom payment terms to align with cash flow needs and payments don't begin until after the funded inventory sells.</p>
					<div className={styles.specialOffer}>WeStock Offer:</div>
					<p className={styles.description}>$50 Amazon gift card after the demo</p>
					<a className={styles.applyButton} href={`mailto:cameron@westock.io?subject=${this.props.brand.name} <> Kickfurther&body=I want to take advantage of this offer.`}>APPLY NOW</a>
				</div>
				<div className={styles.marketplaceSection}>
					<h1 className={styles.title}>Peekage</h1>
					<img className={styles.marketplaceLogo} src='/images/marketplace/PeekageLogo.png'></img>
					<p className={styles.description}>Peekage is an insightful experiential marketing platform that helps brands with reachingtargeted audiences, building awareness, and collecting consumer insights through product testing. Brands build their desired segments using 200+ shopper attributes on the network of 4M Peekage users or they can simply plug their own CRM.</p>
					<a className={styles.applyButton} href={`mailto:cameron@westock.io?subject=${this.props.brand.name} <> Peekage&body=I want to take advantage of this offer.`}>APPLY NOW</a>
				</div>
				<div className={styles.marketplaceSection}>
					<h1 className={styles.title}>myPocketCFO</h1>
					<img className={styles.marketplaceLogo} src='/images/marketplace/MyPocketCFOLogo.png'></img>
					<p className={styles.description}>myPocketCFO is a modern, all-in-one SAAS financial management platform for SMB eCommerce companies. Built for CPG brands, our CPA-CFO-in-the-loop system automates 80%+ of your finance work, produces 10X more accurate financial reports, and curates relevant cash and capital resources.</p>
					<div className={styles.specialOffer}>WeStock Offer:</div>
					<p className={styles.description}>
						$99 / month for the first three months 
						<br />
						$199 / month after the third month
					</p>
					<a className={styles.applyButton} href={`mailto:cameron@westock.io?subject=${this.props.brand.name} <> myPocketCFO&body=I want to take advantage of this offer.`}>APPLY NOW</a>
				</div>
			</div>		
		)
	}
}

export default Marketplace;
