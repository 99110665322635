/// <reference types="react-vis-types" />
import { Component } from 'react';
import styles from './components.module.css'
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { scaleQuantize } from "d3-scale";
import allStates from "./allStates.json";
import ReactToolTip from 'react-tooltip'

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

interface Props {
	data: {[key: string]: number}
	handleMouseClick?: (state: string)=>void
}

interface State {
	tooltip: string
	selected: {[key: string]: number}
}

class USChoropleth extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			tooltip: "",
			selected: {}
		}
	}

	render(){
		let max = 1
		Object.values(this.props.data).forEach((value)=> {
			if(value > max) {
				max = value
			}
		})
		// @ts-ignore
		const colorScale = scaleQuantize().domain([0, max]).range(["#ffedea","#ffcec5","#ffad9f", "#ff8a75", "#ff5533", "#e2492d", "#be3d26", "#9a311f", "#782618"]);
		return (
			<div className={styles.choroplethContainer}>
				{/*
				// @ts-ignore */}
				<ComposableMap className={styles.choroplethContainer} data-tip="" projection="geoAlbersUsa">
					<Geographies geography={geoUrl}>
					{
						// @ts-ignore
					({ geographies }) => geographies.map(geo => {
						const stateID: string = allStates.find(s => s.val === geo.id)!.id;
						// @ts-ignore
						let stateValue = this.props.data[stateID]
						if(stateValue) {

						} else {
							stateValue = 0
						}
						let fill: any = colorScale(stateValue)
						if(this.state.selected[stateID] == 1) {
							fill = "#384457"
						} 
						return (
							<Geography
							key={geo.rsmKey}
							geography={geo}
							onMouseEnter={()=> {
								this.setState({tooltip: `${geo.properties.name} - ${stateValue}`})
							}}
							onMouseLeave={()=>{this.setState({tooltip: ""})}}
							onClick={()=>this.handleMouseClick(geo)}
							// @ts-ignore
							fill={fill}
							style={{
								default: { 
									outline: "none" 
								},
								hover: {
									fill: "rgba(56, 68, 87, .4)",
									outline: "none"
								},
								pressed: { 
									fill: "#384457",
									outline: "none" 
								}
							}}
							/>
						)
						})
					}
					</Geographies>
				</ComposableMap>
				<ReactToolTip>
					{this.state.tooltip}
				</ReactToolTip>
			</div>
		)
	}

	handleMouseClick = (geography: any) => {
		if(this.props.handleMouseClick) {
			const stateID: string = allStates.find(s => s.val === geography.id)!.id;
			let selected = this.state.selected
			if(selected[stateID] == 1) {
				selected[stateID] = 0
			} else {
				selected[stateID] = 1
			}
			let states = ''
			for(let state in selected) {
				if(selected[state] == 1) {
					states = states + state + ','
				}				
			}
			if(states.charAt(states.length - 1) == ',') {
				states = states.slice(0, states.length - 1)
			}
			this.props.handleMouseClick(states)
		}		
	}
}

export default USChoropleth;
