/// <reference types="react-vis-types" />
import { Component } from "react"
import { useNavigate } from "react-router-dom"
import styles from "./tile.module.css"
import Tile, { TileProps } from "./tile"
import { Product } from "../../Models/product"

interface Props extends TileProps {
    topProducts: TopProduct[]
}

class TopProduct {
    product: Product
    count: number
    constructor(product: Product, count: number) {
        this.product = product
        this.count = count
    }
}

const TopProductsTile: React.FC<Props> = ({ navigatePath, topProducts }: Props) => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate("/reports/requests")
    }

    if (topProducts.length >= 3) {
        return (
            <Tile navigatePath={navigatePath}>
                <div className={styles.topProductsContainer}>
                    <div className={styles.topProductContainer1}>
                        <div className={styles.topProductImageContainer}>
                            <img
                                className={styles.topProductImage}
                                src={`${process.env.REACT_APP_IMAGE_URL}/brands/${topProducts[0].product.brandID}/products/${topProducts[0].product.id}/1.jpg`}
                            />
                        </div>
                        <div>
                            <div className={styles.topProductTitleContainer}>
                                <div className={styles.storeCount1}>{topProducts[0].count}</div>
                                <div className={styles.storeName1}>Requests</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.topProductContainer2}>
                        <div className={styles.topProductContainer3}>
                            <div className={styles.topProductImageContainer}>
                                <img
                                    className={styles.topProductImage}
                                    src={`${process.env.REACT_APP_IMAGE_URL}/brands/${topProducts[1].product.brandID}/products/${topProducts[1].product.id}/1.jpg`}
                                />
                            </div>
                            <div className={styles.topProductVerticalCenter}>
                                <div className={styles.storeCount}>{topProducts[1].count}</div>
                                <div className={styles.storeName}>Requests</div>
                            </div>
                        </div>
                        <div className={styles.topProductContainer3}>
                            <div className={styles.topProductImageContainer}>
                                <img
                                    className={styles.topProductImage}
                                    src={`${process.env.REACT_APP_IMAGE_URL}/brands/${topProducts[2].product.brandID}/products/${topProducts[2].product.id}/1.jpg`}
                                />
                            </div>
                            <div className={styles.topProductVerticalCenter}>
                                <div className={styles.storeCount}>{topProducts[2].count}</div>
                                <div className={styles.storeName}>Requests</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Tile>
        )
    } else if (topProducts.length == 2) {
        return (
            <Tile navigatePath={navigatePath}>
                <div className={styles.topProductsContainer}>
                    <div className={styles.topProductContainer1}>
                        <div className={styles.topProductImageContainer}>
                            <img
                                className={styles.topProductImage}
                                src={`${process.env.REACT_APP_IMAGE_URL}/brands/${topProducts[0].product.brandID}/products/${topProducts[0].product.id}/1.jpg`}
                            />
                        </div>
                        <div>
                            <div className={styles.topProductTitleContainer}>
                                <div className={styles.storeCount1}>{topProducts[0].count}</div>
                                <div className={styles.storeName1}>Requests</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.topProductContainer2}>
                        <div className={styles.topProductContainer3}>
                            <div className={styles.topProductImageContainer}>
                                <img
                                    className={styles.topProductImage}
                                    src={`${process.env.REACT_APP_IMAGE_URL}/brands/${topProducts[1].product.brandID}/products/${topProducts[1].product.id}/1.jpg`}
                                />
                            </div>
                            <div className={styles.topProductVerticalCenter}>
                                <div className={styles.storeCount}>{topProducts[1].count}</div>
                                <div className={styles.storeName}>Requests</div>
                            </div>
                        </div>
                        <div className={styles.topProductContainer3}></div>
                    </div>
                </div>
            </Tile>
        )
    } else if (topProducts.length == 1) {
        return (
            <Tile navigatePath={navigatePath}>
                <div className={styles.topProductsContainer}>
                    <div className={styles.topProductContainer1}>
                        <div className={styles.topProductImageContainer}>
                            <img
                                className={styles.topProductImage}
                                src={`${process.env.REACT_APP_IMAGE_URL}/brands/${topProducts[0].product.brandID}/products/${topProducts[0].product.id}/1.jpg`}
                            />
                        </div>
                        <div>
                            <div className={styles.topProductTitleContainer}>
                                <div className={styles.storeCount1}>{topProducts[0].count}</div>
                                <div className={styles.storeName1}>Requests</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.topProductContainer2}>
                        <div className={styles.topProductContainer3}></div>
                        <div className={styles.topProductContainer3}></div>
                    </div>
                </div>
            </Tile>
        )
    } else {
        return <Tile navigatePath={navigatePath}>No Requests</Tile>
    }
}

export default TopProductsTile
