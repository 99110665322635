import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import useAuth from "lib/auth/useAuth"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Loading from "Components/loading"
import { URLS } from "URLS"


export const Logout = () => {
    const { logout } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        const logoutCurrentUser = async () => {
            await logout()
            navigate(URLS.LOGIN)
        }
        logoutCurrentUser()
    }, [])

    return (
        <Container fluid>
            <Row className="justify-content-center">
                <Col md={4} xs={12}>
                    <Loading />
                </Col>
            </Row>
        </Container>
    )
}

export default Logout
