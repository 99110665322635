/// <reference types="react-vis-types" />
import { Component } from "react"
import { useNavigate } from "react-router-dom"
import styles from "./tile.module.css"
import Tile, { TileProps, TileState } from "./tile"

interface Props extends TileProps {
    stat: string
    label: string
}

const SingleStatTile: React.FC<Props> = ({ stat, label, navigatePath }: Props) => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate("/reports/requests")
    }

    return (
        <Tile navigatePath={navigatePath}>
            {stat}
            <p>{label}</p>
        </Tile>
    )
}

export default SingleStatTile
