/// <reference types="react-vis-types" />
import { Component } from 'react';
import { VerticalBarSeries, XAxis, FlexibleXYPlot, YAxis, Hint, RVNearestXData, VerticalBarSeriesPoint } from 'react-vis';
import { format } from 'd3';
import styles from './components.module.css'

interface Props {
	data: {x: string, y: number}[]
	fontSize: number
}

interface State {
	hoverValue?: VerticalBarSeriesPoint
	index?: number
}

const zeroFill = 0.1

class BarGraph extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			hoverValue: undefined,
			index: undefined
		}
	}

	render(){
		const { hoverValue, index } = this.state;
		const data = this.props.data.map((v, i) => ({
			x: v.x,
			// adding 0.1 to 0 so that it shows up.. have to undo this when displaying the value
			y: v.y === 0 ? zeroFill : v.y,
			color: i === index ? 'rgba(56, 68, 87, .5)' : v.y === 0 ? '#E6E6E9' : '#384457'
		}));
		if(data.length == 0) {
			return (
				<div>
					No Data
				</div>
			)
		}

		const dateOptions = { year:'2-digit', month: '2-digit', day: '2-digit', timeZone: 'UTC' }
		return (
			<FlexibleXYPlot xType="ordinal" margin={{left: 50, right: 35, top: 35}} onMouseLeave={this.handleOnMouseLeave}>
				{/*
				// @ts-ignore */}
				<XAxis tickFormat={function tickFormat(d){return (new Date(d).toLocaleDateString('en-US', dateOptions))}}
				tickValues={[this.props.data[0].x, this.props.data[Math.ceil((this.props.data.length-1) / 2)].x, this.props.data[this.props.data.length - 1].x]}
				style={{
					fontSize: '16px', fontWeight: 'normal', 
					line: {
						//stroke: '#C4C4D5'
					}
				}}/>
				<YAxis tickFormat={(tick: any) => {
					if(Math.round(tick) !== tick) {
						return ""
					}
					if(tick < 1) {
						return tick
					}
					return format('.2~s')(tick)}
				} style={{
					fontSize: '16px', fontWeight: 'normal',
					line: {
						//stroke: '#C4C4D5'
					}
				}}/>
				<VerticalBarSeries colorType={'literal'} data={data} animation stroke={'none'} onNearestX={this.handleOnNearestX} />
				{hoverValue ? <Hint value={hoverValue} style={{fontSize: this.props.fontSize}}>
				<div className={styles.hintBody}>
					{/*
					// @ts-ignore */}
					<p><b>{new Date(hoverValue.x).toLocaleDateString('en-US', dateOptions)}</b></p>
					<p>{hoverValue.y == zeroFill ? 0 : hoverValue.y}</p>
				</div>
				</Hint>: null}
			</FlexibleXYPlot>
		)
	}

	handleOnNearestX = (dataPoint: VerticalBarSeriesPoint, data: RVNearestXData<VerticalBarSeriesPoint>) => {
		this.setState({hoverValue: dataPoint, index: data.index})
	}

	handleOnMouseLeave = () => {
		this.setState({hoverValue: undefined, index: undefined})
	}
}

export default BarGraph;
