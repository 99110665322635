import React, { useRef, useEffect, useState, PropsWithChildren } from "react"
import { useNavigate, Link, Navigate } from "react-router-dom"
import Loading from "Components/loading"
import Alert from "react-bootstrap/Alert"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import UnauthenticatedSimpleLayout from "layouts/UnauthenticatedSimpleLayout" // This should live at a higher level
import { AuthAPI } from "services/auth"
import useAuth from "lib/auth/useAuth"
import { URLS } from "URLS"

const Login = () => {
    const email = useRef<HTMLInputElement>(null)
    const password = useRef<HTMLInputElement>(null)
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
    const { login, user } = useAuth()
    const navigate = useNavigate()

    const submitHandler = async (e: React.FormEvent) => {
        e.preventDefault()
        if (email.current && password.current) {
            setIsLoggingIn(true)
            try {
                await login(email.current.value, password.current.value)
                navigate(URLS.DASHBOARD)
            } catch (error) {
                if (!error.response) {
                    setErrorMsg("Error communicating with server")
                } else if (error.response.data.code === "PasswordResetRequiredException") {
                    setErrorMsg(error.response.data.message)
                    navigate(URLS.FORCE_PASSWORD_CHANGE)
                } else if (error.response.data.code === "NotAuthorized") {
                    setErrorMsg(error.response.data.message)
                } else {
                    console.log("error", error, Object.keys(error), error.response)
                    setErrorMsg(error.response.data)
                }
            }
            setIsLoggingIn(false)
        }
    }
    
    if (user) {
        return (<Navigate to={URLS.DASHBOARD} />)
    }

    return (
        <UnauthenticatedSimpleLayout>
            <Container fluid>
                <div className="description text-center">
                    Don't have an account?{" "}
                    <a href={process.env.REACT_APP_SIGNUP_URL}>Get started.</a>
                </div>
                <Row className="justify-content-center">
                    <Col md={4} xs={12}>
                        <Form className="mt-3" onSubmit={submitHandler}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="email">Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    size="lg"
                                    id="email"
                                    ref={email}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="password">Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    size="lg"
                                    id="password"
                                    ref={password}
                                    required
                                />
                                <Form.Text className="helper form-text">
                                    <Link to={URLS.FORGOT_PASSWORD}>Forgot your password?</Link>
                                </Form.Text>
                            </Form.Group>
                            {errorMsg && (
                                <Alert variant="danger">{errorMsg}. Please try again</Alert>
                            )}
                            <div>
                                <Button type="submit" variant="primary" disabled={isLoggingIn}>
                                    Login
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
                {isLoggingIn && <Loading>Logging in...</Loading>}
            </Container>
        </UnauthenticatedSimpleLayout>
    )
}

export default Login
