import * as React from "react"
import { ThemeProvider } from "./Theme"
import { StyletronProvider, engine } from "./Styletron"
import { SWRConfig } from "swr"
import swrConfigs from "hooks/swrConfigs"
import { SurfaceContext } from "./contexts"

const AppProvider = ({ children }: { children: any }) => {
    return (
        <StyletronProvider value={engine}>
            <SurfaceContext.Provider value={'main'}>
                <ThemeProvider>
                    <SWRConfig value={swrConfigs.global}>{children}</SWRConfig>
                </ThemeProvider>
            </SurfaceContext.Provider>
        </StyletronProvider>
    )
}

export default AppProvider
