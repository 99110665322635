import { Component, PropsWithChildren } from "react"
import { useNavigate } from "react-router-dom"
import styles from "./tile.module.css"

export interface TileProps extends PropsWithChildren {
    navigatePath?: string
}

export interface TileState {}

const Tile: React.FC<TileProps> = ({ navigatePath, children }: TileProps) => {
    const navigate = useNavigate()

    const handleClick = () => {
		if(navigatePath) {
			navigate(navigatePath)
		}
    }

    return (
        <div className={styles.tile} onClick={handleClick} id="storeRequestTile">
            {children}
        </div>
    )
}

export default Tile
