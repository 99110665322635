import axios from "axios"

export const api = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_BASE_URL,
})

// defining a custom error handler for all APIs
const errorHandler = (error) => {
    const statusCode = error.response?.status

    if (statusCode === 401 && error.response?.data.message === "Token Expired") {
        // Refresh Token
        const originalRequest = error.config
        if (!originalRequest._retry) {
            return api
                .post("/auth/refresh", {})
                .then((refreshResponse) => {
                    return api(originalRequest)
                })
                .catch((refreshError) => {
                    return Promise.reject(error)
                })
        } else {
            // Already retried. Failed again
            return Promise.reject(error)
        }
    } else if (statusCode && statusCode !== 401) {
        // logging only errors that are not 401
        console.error(error)
    }

    return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})
